import React, { useEffect, useState } from "react";
import CadreDashboard from "../../Biblio/CadreDashboard";
import { Col, Row } from "antd";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
  DollarOutlined,
  TeamOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import "../../styles/dashboardHome.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../apis/store";
import {
  getAllCasesOpportunity,
  getOpportunityByType,
  getStatistcsCases,
} from "../../apis/actions/dashboard.actions";
import { SectionLoader } from "../../Biblio/loaders/SectionLoader";

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const transformMonthData = (monthData: any) => {
  const monthNames = [
    "ديسمبر",
    "نوفمبر",
    "أكتوبر",
    "سبتمبر",
    "أوت",
    "جويلية",
    "جوان",
    "ماي",
    "أفريل",
    "مارس",
    "فيفري",
    "جانفي",
  ];

  if (!monthData) {
    return Array(monthNames.length).fill(0);
  }

  return monthNames && monthNames.map((month) => monthData[month] || 0);
};

const transformAgeData = (ageData: any) => {
  const ageRanges = ["5-9", "10-14", "15-18"];
  const colors = ["#024059", "#FEA432", "#FF5733"];

  // if no data return empty
  if (!ageData) {
    return Array(ageRanges.length).fill(0);
  }

  return (
    ageRanges &&
    ageRanges.map((range, index) => ({
      label: range,
      data: ageRanges.map((_, i) => (i === index ? ageData[range] || 0 : null)),
      borderColor: colors[index],
      backgroundColor: colors[index],
      fill: false,
    }))
  );
};

const DashboardHome = () => {
  const dispatch = useDispatch<AppDispatch>();
  const cases = useSelector(
    (state: RootState) => state?.dashboard?.casesOpportunity?.success?.cases
  );
  const opportunities = useSelector(
    (state: RootState) =>
      state?.dashboard?.casesOpportunity?.success?.opportunities
  );

  const statics = useSelector(
    (state: RootState) => state?.dashboard?.casesStatistics?.success
  );

  const gender = statics?.gender;
  const month = statics?.month;
  const age = statics?.age;

  const oppotunity = useSelector(
    (state: RootState) => state?.dashboard?.opportunityStatistics?.success
  );
const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
      dispatch(getAllCasesOpportunity()).then(() => setLoading(false));
      dispatch(getStatistcsCases()).then(() => setLoading(false));
      dispatch(getOpportunityByType()).then(() => setLoading(false));
  }, [dispatch]);

  const monthData = transformMonthData(month);

  const ageDatasets = transformAgeData(age);

  const data = {
    labels: ["5-9", "10-14", "15-18"],
    datasets: ageDatasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
      },
      title: {
        display: true,
      },
    },

    scales: {
      x: {
        ticks: {
          font: {
            size: 14,
            family: "Beirut",
            weight: 600,
          },
          color: ["#024059", "#FEA432", "#FF5733"],
        },
      },
      y: {
        suggestedMax: 10,
        beginAtZero: true,
      },
    },
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {loading ? (
        <div className="cdis--loader-container">
          <SectionLoader />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Row gutter={16}>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <CadreDashboard
                text="فرص التمويل"
                number={oppotunity?.finance}
                icon={<DollarOutlined className="cdis--dashboard-home-icon" />}
              />
            </Col>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <CadreDashboard
                text="فرص التكوين"
                number={oppotunity?.formation}
                icon={<TrophyOutlined className="cdis--dashboard-home-icon" />}
              />
            </Col>
            <Col xl={8} lg={24} md={24} sm={24} xs={24}>
              <CadreDashboard
                text="المنظورين"
                number={cases}
                icon={<TeamOutlined className="cdis--dashboard-home-icon" />}
              />
            </Col>
          </Row>
          <div>
            <CadreDashboard
              className="cdis--dashboard-home"
              title="عدد المنظورين في الشهر"
            >
              <div className="cdis--dashboard-home-chart-container">
                <Bar
                  data={{
                    labels: [
                      "ديسمبر",
                      "نوفمبر",
                      "أكتوبر",
                      "سبتمبر",
                      "أوت",
                      "جويلية",
                      "جوان",
                      "ماي",
                      "أفريل",
                      "مارس",
                      "فيفري",
                      "جانفي",
                    ],
                    datasets: [
                      {
                        data: monthData,
                        backgroundColor: ["#024059", "#FEA432"],
                        borderColor: ["#024059", "#FEA432"],
                        borderWidth: 1,
                        borderRadius: 15,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },

                    scales: {
                      x: {
                        ticks: {
                          font: {
                            size: 14,
                            family: "Beirut",
                            weight: 600,
                          },
                          color: ["#024059", "#FEA432"],
                        },
                      },
                      y: {
                        suggestedMax: 5,
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </CadreDashboard>
          </div>
          <div>
            <Row gutter={16}>
              <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                <CadreDashboard
                  className="cdis--dashboard-home"
                  title="عدد المنظورين بالعمر"
                >
                  <Bar data={data} options={options} />
                </CadreDashboard>
              </Col>
              <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                <CadreDashboard
                  className="cdis--cadre-dashboard-doughnut"
                  title="عدد المنظورين بالجنس"
                >
                  <Pie
                    data={{
                      labels: ["إناث", "ذكور"],
                      datasets: [
                        {
                          data: [gender?.female, gender?.male],
                          backgroundColor: ["#024059", "#FEA432"],
                          borderColor: ["#024059", "#FEA432"],
                        },
                      ],
                    }}
                  />
                </CadreDashboard>
              </Col>
            </Row>
          </div>
          <div>
            <CadreDashboard className="cdis--dashboard-home" title="عدد الفرص">
              <div className="cdis--dashboard-home-chart-container">
                <Bar
                  data={{
                    labels: ["التكوين", "التمويل"],
                    datasets: [
                      {
                        data: [oppotunity?.formation, oppotunity?.finance],
                        backgroundColor: ["#024059", "#FEA432"],
                        borderColor: ["#024059", "#FEA432"],
                        borderWidth: 1,
                        borderRadius: 15,
                      },
                    ],
                  }}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          font: {
                            size: 14,
                            family: "Beirut",
                            weight: 600,
                          },
                          color: ["#024059", "#FEA432"],
                        },
                      },
                      y: {
                        suggestedMax: 5,
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </CadreDashboard>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardHome;
