import React, { useEffect, useState } from 'react';
import '../../styles/home.scss';
import NavBar from '../communs/NavBar';
import HeroSection from '../../components/section/HeroSection';
import SectionTitle from '../../Biblio/Text/SectionTitle';
import ProgrammsSection from '../../components/section/ProgrammsSection';
import CarousselContainer from '../../Biblio/Caroussel/CarousselContainer';
import OpportunitiesSection from '../../components/section/OpportunitiesSection';
import PartnersSection from '../../components/section/PartnersSection';
import { Divider } from 'antd';
import LocalisationSection from '../../components/section/LocalisationSection';
import Footer from '../communs/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../apis/store';
import { getAllOpportunitiesPublic } from '../../apis/actions/opportunity.actions';
import NewsSection from '../../components/section/NewsSection';
import { getAllNews } from '../../apis/actions/news.actions';
import { SectionLoader } from '../../Biblio/loaders/SectionLoader';

const Home = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState<boolean>(true);
  const opportunities = useSelector((state: RootState) => state?.opportunities?.opportunitiesPublic);

  const news = useSelector((state: RootState) => state?.news?.news);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllOpportunitiesPublic());
      await dispatch(getAllNews());
    };
    fetchData();
  }, [dispatch]);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {loading && (
        <div className="cdis--loader-container">
          <SectionLoader />
        </div>
      )}
      <section className="cdis--base-component-container ">
        <NavBar />
        <HeroSection />
        <div id="about" className="cdis--section-box">
          <SectionTitle className="cdis--divider-section" title="من نحن" />
          <CarousselContainer />
        </div>
        <div className="cdis--section-box">
          <SectionTitle title="البرامج المعتمدة" />
          <ProgrammsSection />
        </div>
        {opportunities && opportunities.length > 0 ? (
          <div id="opportunity" className="cdis--section-box">
            <SectionTitle className="cdis--divider-section " title="الفرص" />
            <OpportunitiesSection />
          </div>
        ) : null}

        {news && news.length > 0 ? (
          <div id="news" className="cdis--section-box">
            <SectionTitle className="cdis--divider-section-news" title="مستجدات" />
            <NewsSection />
          </div>
        ) : null}
        <div id="partners" className="cdis--section-box">
          <SectionTitle className="cdis--divider-section" title="شركائنا" />
          <PartnersSection />
        </div>
        <Divider className={'cdis--title-section-divider'} />
        <LocalisationSection />
        <Footer />
      </section>
    </>
  );
};

export default Home;
