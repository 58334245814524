import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../apis/store';
import logo from '../../assets/logos/cedis-xsmall.svg';
import { getOneNewById } from '../../apis/actions/news.actions';
import { Card, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
const NewDetails = () => {
  const { id } = useParams<{ id: string }>();
  const news = useSelector((state: RootState) => state.news.new);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) {
      dispatch(getOneNewById(id));
    }
  }, [dispatch, id]);

  return (
    <div className="cdis--new-details">
      <div className="cdis--details-container">
        <figure className="cdis--image-container">
          <Link to="/">
            <img alt="logo " src={logo} />
          </Link>
        </figure>
      </div>
      <Row className="cdis--details-item" justify={'center'}>
        <Col xl={2} md={2} lg={2} xs={1} />
        <Col
          xl={20}
          md={20}
          lg={20}
          xs={22}
          style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', paddingBottom: '2vh' }}
        >
          <div className="cdis--details-item-container">
            <h2>{news?.title}</h2>

            <img src={news?.image} alt={news?.title} />
            <p>{news?.description}</p>
          </div>
        </Col>
        <Col xl={2} md={2} lg={2} xs={1} />
      </Row>
    </div>
  );
};
export default NewDetails;
