import React, { useState } from "react";
import TextPrimary from "../Biblio/Text/TextPrimary";
import TextSecondary from "../Biblio/Text/TextSecondary";
import TextBold from "../Biblio/Text/TextBold";
import TextParagraph from "../Biblio/Text/TextParagraph";
import ButtonTextBig from "../Biblio/Buttons/ButtonTextBig";
import ButtonPrimary from "../Biblio/Buttons/ButtonPrimary";
import ButtonSecondary from "../Biblio/Buttons/ButtonSecondary";
import ButtonOutlined from "../Biblio/Buttons/ButtonOutlined";
import ButtonFaded from "../Biblio/Buttons/ButtonFaded";
import CadreText from "../Biblio/CadreText";
import StepsComponent from "../Biblio/Steps";
import PaginationTable from "../Biblio/PaginationTable";
import InputWithoutIconHuge from "../Biblio/Inputs/InputWithoutIconHuge";
import InputWithoutIconExtralarge from "../Biblio/Inputs/InputWithoutIconExtralarge";
import InputWithoutIconLarge from "../Biblio/Inputs/InputWithoutIconLarge";
import InputWithoutIconMediumLarge from "../Biblio/Inputs/InputWithoutIconMediumLarge";
import InputWithoutIconMedium from "../Biblio/Inputs/InputWithoutIconMedium";
import InputWithoutIconMediumSmall from "../Biblio/Inputs/InputWithoutIconMediumSmall";
import InputWithoutIconNormal from "../Biblio/Inputs/InputWithoutIconNormal";
import InputWithoutIconSmall from "../Biblio/Inputs/InputWithoutIconSmall";
import InputWithIcon from "../Biblio/Inputs/InputWithIcon";
import SelectLarge from "../Biblio/Selects/SelectLarge";
import SelectMedium from "../Biblio/Selects/SelectMedium";
import SelectSmall from "../Biblio/Selects/SelectSmall";
import usernameIcon from "../assets/icons/username.svg";
import passwordIcon from "../assets/icons/password.svg";
import checked from "../assets/icons/checked.svg";
import OportunityCard from "../Biblio/cards/OportunityCard";
import Image from "../assets/images/image.svg";
import CarousselContainer from "../Biblio/Caroussel/CarousselContainer";
import SearchInput from "../Biblio/SearchInput";
import { GetProps, Input } from "antd";

interface Step {
  title: JSX.Element;
  content: JSX.Element;
  disabled?: boolean;
}

type SearchProps = GetProps<typeof Input.Search>;

const onSearch: SearchProps["onSearch"] = (value, _e, info) => {};

const Biblio = () => {
  const options = ["ذكر", "أنثى", "آخر"];

  const [current, setCurrent] = useState<number>(0);

  const steps: Step[] = [
    {
      title: (
        <div className="step-title">
          <span className="step-title-number">1</span>
          <span>المصدرالإحالة</span>
        </div>
      ),
      content: (
        <>
          <div className="step-form">
            <InputWithoutIconExtralarge type="text" placeholder="الهاتف" />
            <InputWithoutIconExtralarge
              type="text"
              placeholder="الاسم واللقب"
            />

            <InputWithoutIconExtralarge
              type="text"
              placeholder="البريد الإلكتروني"
            />
            <InputWithoutIconExtralarge type="text" placeholder="الصفة" />
          </div>
        </>
      ),
    },
    {
      title: (
        <div className="step-title">
          <span className="step-title-number">2</span>
          <span>موضوع الإحالة</span>
          {current > 1 && (
            <img className="cedis--img-step" src={checked} alt="checked" />
          )}
        </div>
      ),
      content: (
        <>
          <div className="step-form">
            <SelectLarge
              onChange={() => {}}
              selectPlaceholder="أختر موضوع الإحالة"
              options={options}
            />
          </div>
        </>
      ),
      disabled: current < 1,
    },
    {
      title: (
        <div className="step-title">
          <span className="step-title-number">3</span>
          <span>المعني</span>
          {current > 2 && <img src={checked} alt="checked" />}
        </div>
      ),
      content: <></>,
      disabled: current < 2,
    },
  ];

  return (
    <div className="App">
      <table className="table-container">
        <thead>
          <tr>
            <th>Component Type</th>
            <th>Component Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <h4>Text primary:</h4>
            </td>
            <td>
              <TextPrimary title="مركز الدفاع والادماج الاجتماعي بقابس" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Text secondary:</h4>
            </td>
            <td>
              <TextSecondary title="تسجيل الدخول" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Text Bold medium:</h4>
            </td>
            <td>
              <TextBold title="برنامج الوقاية والتأهيل التربوي" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Text paragraph:</h4>
            </td>
            <td>
              <TextParagraph title="مركز الدفاع والادماج الاجتماعي بقابس" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Button Text Big:</h4>
            </td>
            <td>
              <ButtonTextBig title="تسجيل الدخول" onClick={() => {}} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Button Primary:</h4>
            </td>
            <td>
              <ButtonPrimary title="السابق" onClick={() => {}} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Button secondary:</h4>
            </td>
            <td>
              <ButtonSecondary title="التالي" onClick={() => {}} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Button outlined:</h4>
            </td>
            <td>
              <ButtonOutlined title="تسجيل الدخول" onClick={() => {}} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Button logout:</h4>
            </td>
            <td>
              <ButtonFaded title="الخروج" onClick={() => {}} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Cadre text:</h4>
            </td>
            <td>
              <CadreText title="" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Steps:</h4>
            </td>
            <td>
              <StepsComponent current={current} steps={steps} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Pagination:</h4>
            </td>
            <td>{/* <PaginationTable defaultCurrent={1} total={50} /> */}</td>
          </tr>
          <tr>
            <td>
              <h4>Huge Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconHuge
                onChange={() => {}}
                type="text"
                placeholder="الاسم واللقب"
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4>ExtraLarge Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconExtralarge
                type="text"
                placeholder="الاسم واللقب"
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Large Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconLarge type="text" placeholder="عدد ب ت و" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Meduim Large Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconMediumLarge
                type="text"
                placeholder="رقم الهاتف"
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Meduim Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconMedium
                type="text"
                placeholder="المستوى التعليمي"
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Meduim Small Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconMediumSmall
                type="text"
                placeholder="الحالة المدنية"
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Normal Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconNormal type="text" placeholder="18-09-1999" />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Small Input Without Icon:</h4>
            </td>
            <td>
              <InputWithoutIconSmall type="text" placeholder="قابس" />
            </td>
          </tr>
          <tr>
            <td>
              <h4> Input With username Icon:</h4>
            </td>
            <td>
              <InputWithIcon
                type="text"
                placeholder="إسم المستخدم"
                icon={usernameIcon}
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4> Input With password Icon:</h4>
            </td>
            <td>
              <InputWithIcon
                type="password"
                placeholder="كلمة السر"
                icon={passwordIcon}
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4> Large Select</h4>
            </td>
            <td>
              <SelectLarge
                onChange={() => {}}
                selectPlaceholder="اختر الجنس"
                options={options}
              />
            </td>
          </tr>
          <tr>
            <td>
              <h4> Meduim Select</h4>
            </td>
            <td>
              <SelectMedium selectPlaceholder="اختر الجنس" options={options} />
            </td>
          </tr>
          <tr>
            <td>
              <h4> SmallSelect</h4>
            </td>
            <td>
              <SelectSmall selectPlaceholder="اختر الجنس" options={options} />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Oportunity Card</h4>
            </td>
            <td>
              {/* <OportunityCard
                urlImage={Image}
                title={"دورة تكوينية الحلاقة و التجميل "}
                subtitle="عدد المشاركين"
                number="05"
                total="40"
              /> */}
            </td>
          </tr>
          <tr>
            <td>
              <h4>Caroussel </h4>
            </td>
            <td>
              <CarousselContainer />
            </td>
          </tr>
          <tr>
            <td>
              <h4>Input search</h4>
            </td>
            <td>
              <SearchInput placeholder="... بحث" onSearch={onSearch} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Biblio;
