import React, { useState } from "react";
import TextPrimary from "../Text/TextPrimary";
import TextParagraph from "../Text/TextParagraph";
import "../../styles/caroussel.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";

interface CarousselContentProps extends React.HTMLAttributes<HTMLDivElement> {}
const CarousselContent = ({ ...props }: CarousselContentProps) => {
  //State Count used to count the table
  const [count, setCount] = useState(0);
  //List of Table
  const tab = [
    "هو مؤسسة عمومية ذات صبغة إدارية تتمتع بالشخصية المعنوية والاستقلال المالي ويخضع لإشراف وزارة الشؤون الاجتماعية احدث بمقتضى أمر عدد 1252 لسنة 2002 المؤرخ في 27 ماي 2002 .",
    "تضبط مهام المركز حسب القانون عدد 109لسنة 1993 المؤرخ في 08 نوفمبر 1993 وينص الفصل الثاني على:\n- المساهمة في الكشف المبكر عن الظروف والوضعيات التي يمكن ان تؤدي الى الانحراف وعدم التكيف الاجتماعي .\n- إرساء نظام رصد وجمع ومعالجة المعطيات المتعلقة بمظاهر عدم التكيف والقيام بدراسات متعددة الاختصاص حول هذه الظواهر .",
    "- توجيه وارشاد الأشخاص الذين يعيشون ظروفا صعبة نحو الهياكل  التي تساعد على ادماجهم .\n- المساهمة في الإحاطة الاجتماعية والتربوية بالأشخاص المنحرفين والمهددين بالانحراف ومتابعتهم ومساعدتهم بواسطة تدخلات ملائمة تهدف إلى إعادة تأهيلهم اجتماعيا .",
  ];
  return (
    <div
      {...props}
      className={`cdis--caroussel-content-container ${props.className ?? ""}`}
    >
      <div className="cdis--description-container">
        <TextPrimary title="مركز الدفاع والادماج الاجتماعي بقابس" />

        {tab[count].split("\n").map((paragraph, index) => (
          <TextParagraph
            key={index}
            className="cdis--text-align"
            title={paragraph.trim()}
          />
        ))}
      </div>

      <div className="cdis--buttons-container">
        <button onClick={() => setCount(count < 2 ? count + 1 : 2)}>
          <ArrowLeftOutlined />
        </button>
        <div className="cdis--steps-container">
          {[2, 1, 0].map((step) => (
            <div
              key={step}
              className={
                count === step
                  ? "cdis--steps-selected"
                  : "cdis--steps-unselected"
              }
              onClick={() => setCount(step)}
            ></div>
          ))}
        </div>
        <button
          onClick={() => {
            setCount(count > 0 ? count - 1 : 0);
          }}
        >
          <ArrowRightOutlined />
        </button>
      </div>
    </div>
  );
};

export default CarousselContent;
