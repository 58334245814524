import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { New } from "../../interfaces/new.types";
import {
  addNew,
  deleteNew,
  getAllNews,
  getOneNewById,
  updateNew,
} from "../actions/news.actions";
interface NewState {
  new: New;
  news: New[];
  loading: boolean;
  error: string | null;
}
const initialState: NewState = {
  new: {} as New,
  news: [],
  loading: false,
  error: null,
};
const newSlice = createSlice({
  name: "news",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNew.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addNew.fulfilled, (state, action: PayloadAction<New>) => {
        state.loading = false;
        state.new = action.payload;
      })
      .addCase(addNew.rejected, (state, action) => {
        state.loading = false;
        state.new = {} as New;
        state.error = action.error.message || "Failed to add new";
      })
      // Handle getAllNews actions
      .addCase(getAllNews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllNews.fulfilled, (state, action: PayloadAction<New[]>) => {
        state.loading = false;
        state.news = action.payload;
      })
      .addCase(getAllNews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch";
      })
      // Handle updateNew actions
      .addCase(updateNew.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateNew.fulfilled, (state, action: PayloadAction<New>) => {
        state.loading = false;
        state.news = state?.news?.map((annonce) =>
          annonce.id === action.payload.id ? action.payload : annonce
        );
      })
      .addCase(updateNew.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update";
      })
      // Handle deleteNew actions
      .addCase(deleteNew.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteNew.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.news = state.news.filter(
          (annonce) => annonce.id !== action.payload
        );
      })
      .addCase(deleteNew.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete ";
      })
      // Handle getOneById actions
      .addCase(getOneNewById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getOneNewById.fulfilled, (state, action: PayloadAction<New>) => {
        state.loading = false;
        state.new = action.payload;
      })
      .addCase(getOneNewById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch";
      });
  },
});
export default newSlice.reducer;
