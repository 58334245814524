import React from 'react';
import '../../styles/buttons.scss';
import ButtonLoader from '../loaders/ButtonLoader';

interface ButtonSecondaryProps {
  onClick?: () => void;
  title: string;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonSecondary = ({ title, onClick, disabled, loading }: ButtonSecondaryProps) => {
  return (
    <button
      disabled={disabled}
      className={`cdis--button-secondary ${disabled ? 'cdis--button-disabled' : ''}`}
      onClick={onClick}
    >
      {title}
      {loading ? <ButtonLoader /> : <div style={{ width: '20px', padding: '8px' }} />}
    </button>
  );
};

export default ButtonSecondary;
