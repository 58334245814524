import React, { useEffect, useState } from "react";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import { Button, Divider, Modal, Upload, UploadFile } from "antd";
import InputWithoutIconMedium from "../../../Biblio/Inputs/InputWithoutIconMedium";
import "../../../styles/modals.scss";
import { New } from "../../../interfaces/new.types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../apis/store";
import { getAllNews, updateNew } from "../../../apis/actions/news.actions";
import ModalConfirm from "./ModalConfirm";
import { UploadOutlined } from "@ant-design/icons";
interface ModalProps {
  annonce: any;
}
const EditNewModal = ({ annonce, ...props }: ModalProps) => {
  const [isModal, setIsModal] = useState(false);
  const showModal = () => {
    setIsModal(true);
  };
  // Function to close the modal
  const handleCancel = () => {
    setIsModal(false);
  };
  const [editNew, setEditNew] = useState<New>({
    id: annonce?.id,
    title: annonce?.title,
    description: annonce?.description,
    image: annonce?.image,
  });
  useEffect(() => {
    setEditNew({
      id: annonce?.id,
      title: annonce?.title,
      description: annonce?.description,
      image: annonce?.image,
    });
  }, [annonce]);
  // State to keep track of the uploaded files
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // Function to handle file uploads
  useEffect(() => {
    if (editNew?.image) {
      setFileList([
        {
          uid: "-1", // Unique identifier for the file
          name: "image.jpg", // Name of the file
          status: "done", // Status of the file
          url: editNew?.image, // URL for preview
        } as UploadFile,
      ]);
    }
  }, [editNew?.image]);
  const handleFileUpload = (info: any) => {
    // Extract the file object from the upload event
    const file = info.file.originFileObj;

    // If there is a file, read it as a Data URL and update the state
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setEditNew((prevState) => ({
          ...prevState,
          image: reader.result as string, // Update the image in the opportunity state
        }));
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error); // Log any errors
      };
    }

    // Update the file list state
    setFileList(info.fileList);
  };
  
  // Use dispatch from Redux for state management
  const dispatch = useDispatch<AppDispatch>();
  const handleUpdateNew = async () => {
    dispatch(updateNew({ id: annonce?.id, annonce: editNew })).then(() => {
      dispatch(getAllNews());
    });
  };
  return (
    <>
      <ButtonOutlined
        onClick={showModal}
        title="تعديل"
      />

      <Modal open={isModal} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1>تعديل</h1>
          <h4>{annonce?.title}</h4>
          <Divider className="cdis--divider" />
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4>العنوان :</h4>

            <InputWithoutIconMedium
              value={editNew?.title}
              className="cdis--modal-edit-opportunity-input"
              placeholder="العنوان ..."
              type="text"
              onChange={(event) => {
                setEditNew({
                  ...editNew,
                  title: event.target.value,
                });
              }}
            />
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--modal-h4">تحميل صورة :</h4>

            <Upload
              fileList={fileList}
              className="cdis--upload-file"
              name="image"
              accept=".png,.jpg,.jpeg"
              onChange={handleFileUpload}
            >
              <Button className="cdis--upload" icon={<UploadOutlined />}>
                انقر لتحميل
              </Button>
            </Upload>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4>التعريف :</h4>
            <textarea
              className="cdis--new-textarea"
              placeholder="التعريف ..."
              value={editNew?.description}
              onChange={(event) => {
                setEditNew({
                  ...editNew,
                  description: event.target.value,
                });
              }}
            />
          </div>
          <div className="cdis--modal-buttons">
            <ModalConfirm
              editState={editNew}
              handleUpdate={handleUpdateNew}
              setIsModal={setIsModal}
            />
            <ButtonOutlined
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditNewModal;
