import React, { useEffect } from "react";
import CardWithDescription from "../../Biblio/cards/CardWithDescription";
import NewsCard from "../../Biblio/cards/NewsCard";
import "../../styles/newsSection.scss";
import CardNewsWithTitle from "../../Biblio/cards/CardNewsWithTitle";
import { getAllNews } from "../../apis/actions/news.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../apis/store";
import { Col, Row } from "antd";
const NewsSection = () => {
  const dispatch = useDispatch<AppDispatch>();
  const news = useSelector((state: RootState) => state?.news?.news);

  useEffect(() => {
    dispatch(getAllNews());
  }, [dispatch]);
  return (
    <section className="cdis--news-section">
      {news.length > 0 && (
        <Row gutter={48} style={{ width: "100%", direction: "rtl" }}>
          <Col xl={12} lg={12} md={12} xs={24}>
            <CardWithDescription
              urlImage={news[0]?.image}
              title={news[0]?.title}
              description={news[0]?.description}
              id={news[0]?.id}
            />
          </Col>
          {news.length > 1 && (
            <Col xl={12} lg={12} md={12} xs={24}>
              <NewsCard
                urlImage={news[1]?.image}
                title={news[1]?.title}
                description={news[1]?.description}
                id={news[1]?.id}
              />
            </Col>
          )}
        </Row>
      )}
      {news.length > 2 && (
        <Row gutter={48} style={{ width: "100%", direction: "rtl" }}>
          <Col xl={12} lg={12} md={12} xs={24}>
            <CardNewsWithTitle
              urlImage={news[2]?.image}
              title={news[2]?.title}
              id={news[2]?.id}
            />
          </Col>
          {news.length > 3 && (
            <Col xl={6} lg={6} md={6} xs={24}>
              <CardNewsWithTitle
                urlImage={news[3]?.image}
                title={news[3]?.title}
                id={news[3]?.id}
              />
            </Col>
          )}

          {news.length > 4 && (
            <Col xl={6} lg={6} md={6} xs={24}>
              <NewsCard
                urlImage={news[4]?.image}
                title={news[4]?.title}
                description={news[4]?.description}
                id={news[4]?.id}
              />
            </Col>
          )}
        </Row>
      )}
      {/* {news.length > 2 && (
        <div className="cdis--news-box-content">
          <div className="cdis--news-card-container">
            <CardNewsWithTitle urlImage={news[2].image} title={news[2].title} />
            {news.length > 3 && <CardNewsWithTitle urlImage={news[3].image} title={news[3].title} />}
          </div>
          {news.length > 4 && (
            <NewsCard urlImage={news[4].image} title={news[4].title} description={news[4].description} />
          )}
        </div>
      )} */}
    </section>
  );
};

export default NewsSection;
