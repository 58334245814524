import React, { useState } from "react";
import "../../styles/navbar.scss";
import BurgerItemsList from "../../Biblio/Tabs/BurgerItemsList";
import ButtonPrimary from "../../Biblio/Buttons/ButtonPrimary";
import ButtonOutlined from "../../Biblio/Buttons/ButtonOutlined";
import { useNavigate } from "react-router-dom";
interface BurgerMenuProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const BurgerMenu = ({ ...props }: BurgerMenuProps) => {
  //state for burger menu--------------------------------------
  const [isBurgerOpened, setIsBurgerOpened] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <button
        className="cdis--burger-btn-container"
        onClick={() => {
          setIsBurgerOpened(!isBurgerOpened);
        }}
      >
        <span className={isBurgerOpened ? "bar-1" : "bar"}></span>
        <span className={isBurgerOpened ? "bar-2" : "bar"}></span>
        <span className={isBurgerOpened ? "bar-3" : "bar"}></span>
      </button>
      <nav 
        {...props}
        className={`${
          isBurgerOpened
            ? "cdis--burger-menu-container-opened"
            : "cdis--burger-menu-container"
        } ${props.className ? props.className : ""}`}
      >
        {isBurgerOpened && (
          <div className="cdis--list-burger-items">
            <BurgerItemsList setIsBurgerOpened={setIsBurgerOpened} />
            <ButtonPrimary
              className="cdis--primary-btn"
              title="تسجيل الدخول"
              onClick={() => navigate("/login")}
            />
            <ButtonOutlined
              className="cdis--outlined-btn"
              title="إضافة حالة "
              onClick={() => navigate("/form")}
            />
          </div>
        )}
      </nav>
    </>
  );
};

export default BurgerMenu;
