import React, { useEffect, useState, useRef } from "react";
import "../../styles/navbar.scss";
import { sections } from "../../data/data";
import { getAllOpportunitiesPublic } from "../../apis/actions/opportunity.actions";
import { getAllNews } from "../../apis/actions/news.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../apis/store";
// Define the props interface for the BurgerItemsList component
interface BurgerTabsProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  setIsBurgerOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

const BurgerItemsList = ({ setIsBurgerOpened, ...props }: BurgerTabsProps) => {
  const [didSelectItem, setDidSelectItem] = useState("الرئيسية"); // State to track the selected item
  const [isScrolling, setIsScrolling] = useState(false); // State to track if the page is scrolling
  const sectionRefs = useRef<Record<string, HTMLElement | null>>({}); // Refs to store section elements
  useEffect(() => {
    // Retrieve the selected item from localStorage
    const savedItem = localStorage.getItem("selectedItem");
    const sectionId = savedItem
      ? sections.find((section) => section.label === savedItem)?.id
      : "accueil";
    const section = sectionRefs.current[sectionId || "accueil"];

    if (section) {
      const navbar = document.getElementById("navbar");
      if (navbar) {
        const navbarHeight = navbar.offsetHeight + 800;
        const sectionPosition =
          section.getBoundingClientRect().top + window.scrollY;
        const navbarHeighttop = navbar.offsetHeight;
        window.scrollTo({
          top: sectionPosition - navbarHeighttop,
          behavior: "smooth",
        });
      } else {
        section.scrollIntoView({ behavior: "smooth" });
      }
      setDidSelectItem(savedItem || "الرئيسية");
    }

    // Reset to "الرئيسية" on refresh
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("selectedItem", "الرئيسية");
    });

    return () => {
      window.removeEventListener("beforeunload", () => {
        localStorage.setItem("selectedItem", "الرئيسية");
      });
    };
  }, []);

  useEffect(() => {
    // Store references to the section elements
    sections.forEach((section) => {
      sectionRefs.current[section.id] = document.getElementById(section.id);
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            const matchedSection = sections.find(
              (section) => section.id === sectionId
            );
            if (matchedSection && !isScrolling) {
              setDidSelectItem(matchedSection.label); // Update selected item when intersecting
              localStorage.setItem("selectedItem", matchedSection.label); // Store the selected item in localStorage
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    Object.values(sectionRefs.current).forEach((section) => {
      if (section) observer.observe(section); // Observe each section
    });

    return () => {
      observer.disconnect(); // Cleanup observer on unmount
    };
  }, [isScrolling]);

  const handleScroll = (id: string, label: string) => {
    const section = document.getElementById(id);
    if (section) {
      const navbar = document.getElementById("navbar");
      const navbarHeight = navbar ? navbar.offsetHeight : 0;
      const sectionPosition = section.offsetTop - navbarHeight;
      setIsScrolling(true); // Set scrolling state
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
      setTimeout(() => {
        setIsScrolling(false); // Reset scrolling state after scrolling ends
        setIsBurgerOpened(false); // Close the burger menu
      }, 1000);
    }
  };

  const handleClick =
    (id: string, label: string) => (event: React.MouseEvent) => {
      event.preventDefault();
      setDidSelectItem(label); // Update the selected item immediately
      localStorage.setItem("selectedItem", label); // Store the selected item in localStorage
      handleScroll(id, label); // Scroll to the section
    };
const dispatch = useDispatch<AppDispatch>();
const opportunities = useSelector(
  (state: RootState) => state?.opportunities?.opportunitiesPublic || []
);

const news = useSelector((state: RootState) => state?.news?.news || []);

useEffect(() => {
  const fetchData = async () => {
    dispatch(getAllOpportunitiesPublic());
    dispatch(getAllNews());
  };
  fetchData();
}, [dispatch]);

const filteredSections = sections.filter((section) => {
  if (section.id === "opportunity" && opportunities.length === 0) {
    return false;
  }
  if (section.id === "news" && news.length === 0) {
    return false;
  }
  return true;
});
  return (
    <div
      id="navbar"
      {...props}
      className={`cdis--burger-menu-content ${props.className ?? ""}`}
    >
      <ul className="cdis--burger-menu-list">
        {filteredSections.map((section) => (
          <li
            key={section.id}
            id={didSelectItem === section.label ? "selected-item" : ""}
            className="cdis--list-item"
            onClick={handleClick(section.id, section.label)}
          >
            {section.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BurgerItemsList;
