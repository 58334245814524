import React from 'react'
import "../../styles/cards.scss"
import { useNavigate } from 'react-router-dom';
interface CardWithDescriptionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  urlImage:string;
  title:string;
  description:string;
  id:string;
}
const CardWithDescription = ({...props}: CardWithDescriptionProps) => {
   const navigate = useNavigate();

   const handleClick = () => {
     navigate(`/news/${props?.id}`);
   };
  return (
    <div onClick={handleClick} className="cdis--card-with-description">
      <figure className="cdis--image-container">
        <img alt="" src={props?.urlImage} />
      </figure>
      <div className="cdis--description-container">
        <h6>{props?.title}</h6>
        <p>{props?.description}</p>
      </div>
    </div>
  );
}

export default CardWithDescription