import React from 'react'
import "../../styles/cards.scss";
import { useNavigate } from 'react-router-dom';
interface NewsCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  urlImage: string;
  title: string;
  description: string;
  id: string;
}
const NewsCard = ({ ...props }: NewsCardProps) => {
   const navigate = useNavigate();

   const handleClick = () => {
     navigate(`/news/${props?.id}`);
   };
  return (
    <div onClick={handleClick} className="cdis--news-card">
      <figure className="cdis--image-container">
        <img alt="" src={props.urlImage} />
      </figure>
      <div className="cdis--description-container">
        <h6>{props.title}</h6>
        <p>{props.description}</p>
      </div>
    </div>
  );
};

export default NewsCard