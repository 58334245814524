import React from "react";
import "../../styles/footer.scss";
import { Divider } from "antd";
import logoFooter from "../../assets/logos/cedis-white.svg";
const Footer = () => {
  return (
    <section className="cdis--footer-container">
      <div className="cdis--footer-titles-container">
        <h4 className="cdis--footer-title">مركز الدفاع والادماج</h4>
        <h4 className="cdis--footer-title">الاجتماعي بقـــــــــــــــــابس</h4>
      </div>
      <div className="cdis--footer-section-box">
        <figure className="cdis--image-container">
          <img src={logoFooter} alt="" />
        </figure>
        <Divider className="cdis--footer-divider" />
        <div className="cdis--footer-details">
          <h6 className="cdis--details-item">
            للإتصال بنا : 177 394 75 (216+)
          </h6>
          <h6 className="cdis--details-item">
            البريد الإلكتروني :cdis.gabes@social.gov [.tn]
          </h6>
        </div>
      </div>
      <h4 className="cdis--details-item">
        Crédit : Cody.tn - Code Your Future
      </h4>
    </section>
  );
};

export default Footer;
