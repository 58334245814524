import React from "react";
import "../../styles/text.scss";

interface MyTextBoldProps {
  title: string;
}

const TextBold = ({ title }: MyTextBoldProps) => {
  return <span className="cdis--text-bold-medium">{title}</span>;
};

export default TextBold;
