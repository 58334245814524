import React, { useEffect, useState } from "react";
import { Divider, message, Modal } from "antd";
import ButtonOutlined from "../../Biblio/Buttons/ButtonOutlined";
import InputWithoutIconMedium from "../../Biblio/Inputs/InputWithoutIconMedium";
import ButtonSecondary from "../../Biblio/Buttons/ButtonSecondary";
import "../../styles/modals.scss";
import { addCandidate } from "../../apis/actions/candidate.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../apis/store";
import { Opportunity } from "../../interfaces/opportunity.types";
import { getAllOpportunitiesPublic } from "../../apis/actions/opportunity.actions";
import * as Yup from "yup";
import { Candidate } from "../../interfaces/candidate.types";
interface ModalProps {
  onOpenModal?: () => void;
  onCloseModal?: () => void;
  opportunity: Opportunity;
}

const CandidateModal = ({
  opportunity,
  onOpenModal,
  onCloseModal,
  ...props
}: ModalProps) => {
  const [candidate, setCandidate] = useState({
    fullName: "",
    adress: "",
    phoneNumber: 0,
    opportunity: { id: opportunity?.id },
  });
  // State to control the loading
  const [isLoading, setIsLoading] = useState(false);
  //validation inputs
  const validationSchema = Yup.object({
    fullName: Yup.string().required("يرجى إدخال الاسم الكامل"),
    adress: Yup.string().required("يرجى إدخال العنوان"),

    phoneNumber: Yup.number()
      .test(
        "is-required",
        "يرجى إدخال رقم الهاتف",
        (value) => value !== undefined && value !== 0
      )
      .test(
        "is-valid-number",
        "رقم الهاتف غير صحيح",
        (value) =>
          !value ||
          (!isNaN(Number(value)) &&
            Number(value) > 0 &&
            Number.isInteger(Number(value)))
      )
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
  });
  const [errors, setErrors] = useState<
    Partial<Record<keyof Candidate, string>>
  >({});
  const [isModal, setIsModal] = useState(false);
  // Function to show the modal
  const showModal = () => {
    setIsModal(true);
    if (onOpenModal) {
      onOpenModal();
    }
  };

  // Function to handle the modal cancel action
  const handleCancel = () => {
    setIsModal(false);
    if (onCloseModal && isLoading === false) {
      setErrors({});
      onCloseModal();
    }
  };
  // Use dispatch from Redux for state management
  const dispatch = useDispatch<AppDispatch>();
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(candidate, { abortEarly: false });
      await dispatch(addCandidate(candidate)).then(() => {
        setIsLoading(false);
        dispatch(getAllOpportunitiesPublic());
      });
      handleCancel();
    } catch (err) {
      const validationErrors: Partial<Record<keyof Candidate, string>> = {};
      if (err instanceof Yup.ValidationError && err.inner) {
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path as keyof Candidate] = error.message;
          }
        });
      }
      setErrors(validationErrors);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [errors]);
  return (
    <>
      <ButtonSecondary
        onClick={() => {
          showModal();
        }}
        title="ترشح"
      />
      <Modal open={isModal} footer={null} onCancel={() => handleCancel()}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1>إضافة مشارك</h1>
          <Divider className="cdis--divider" />
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--modal-h4"> الاسم و اللقب : </h4>
            <div className="cids-input-error">
              <InputWithoutIconMedium
                id="title"
                type="text"
                placeholder=""
                className="cdis--modal-input1"
                onChange={(event) => {
                  setCandidate({
                    ...candidate,
                    fullName: event.target.value,
                  });
                }}
              />
              {errors?.fullName && <span>{errors?.fullName}</span>}
            </div>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--modal-h4">رقم الهاتف :</h4>
            <div className="cids-input-error">
              <InputWithoutIconMedium
                id="description"
                type="tel"
                placeholder=""
                className="cdis--modal-input2"
                onChange={(event) => {
                  setCandidate({
                    ...candidate,
                    phoneNumber: Number(event.target.value),
                  });
                }}
              />
              {errors.phoneNumber && <span>{errors.phoneNumber}</span>}
            </div>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--modal-h4">العنوان :</h4>
            <div className="cids-input-error">
              <InputWithoutIconMedium
                id="title"
                type="text"
                placeholder=""
                className="cdis--modal-input1"
                onChange={(event) => {
                  setCandidate({
                    ...candidate,
                    adress: event.target.value,
                  });
                }}
              />
              {errors.adress && <span>{errors.adress}</span>}
            </div>
          </div>

          <div className="cdis--modal-buttons">
            <ButtonSecondary
              onClick={() => {
                setIsLoading(true);
                handleSubmit();
              }}
              title="إضافة"
              loading={isLoading}
            />
            <ButtonOutlined
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CandidateModal;
