import React, { useEffect, useState } from "react";
import { Button, Divider, message, Modal, Upload } from "antd";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import InputWithoutIconMedium from "../../../Biblio/Inputs/InputWithoutIconMedium";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import "../../../styles/modals.scss";
import icon from "../../../assets/icons/add.svg";
import ButtonWithIcon from "../../../Biblio/Buttons/ButtonWithIcon";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../apis/store";
import {
  addOpportunity,
  getAllOpportunities,
} from "../../../apis/actions/opportunity.actions";
import { RcFile } from "antd/es/upload";
import SelectSmall from "../../../Biblio/Selects/SelectSmall";
import * as Yup from "yup";
import { Opportunity } from "../../../interfaces/opportunity.types";
import moment from "moment";
// Define the props for the modal component (currently empty)
interface ModalProps {
  activeButton: string;
  onOpportunityAdded: (type: string) => void;
}

const validationSchema = Yup.object({
  title: Yup.string().required("يرجى إدخال اسم الدورة"),
  type: Yup.string().required("يرجى إدخال نوع الفرصة"),
  numberParticipants: Yup.number()
    .test(
      "is-required",
      "يرجى إدخال عدد المشاركين",
      (value) => value !== undefined && value !== 0
    )
    .test(
      "is-valid-number",
      " عدد المشاركين يجب أن يكون عدداً صحيحاً   ",
      (value) =>
        !value ||
        (!isNaN(Number(value)) &&
          Number(value) > 0 &&
          Number.isInteger(Number(value)))
    )
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),

  image: Yup.string().required("يرجى إدخال الصورة"),
});
const AddOpportunityModal = ({ ...props }: ModalProps) => {
  const [errors, setErrors] = useState<
    Partial<Record<keyof Opportunity, string>>
  >({});
  // State to control the visibility of the modal
  const [isModal, setIsModal] = useState(false);
  // State to control the loading 
  const [isLoading, setIsLoading] = useState(false);
  // State to manage the form data for adding an opportunity
  const [opportunity, setOpportunity] = useState({
    title: "",
    type: "",
    numberParticipants: 0,
    endDate: moment().format("YYYY-MM-DD"), // Default to today's date
    image: "",
  });

  // Use dispatch from Redux for state management
  const dispatch = useDispatch<AppDispatch>();

  // Function to open the modal
  const showModal = () => {
    setIsModal(true);
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModal(false);
    setErrors({});
  };

  // State to keep track of the uploaded files
  const [fileList, setFileList] = useState<RcFile[]>([]);

  // Function to handle file uploads
  const handleFileUpload = (info: any) => {
    // Extract the file object from the upload event
    const file = info.file.originFileObj;

    // If there is a file, read it as a Data URL and update the state
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setOpportunity((prevState) => ({
          ...prevState,
          image: reader.result as string, // Update the image in the opportunity state
        }));
      };
      reader.onerror = (error) => {
        console.error("خطأ في قراءة الملف", error); // Log any errors
      };
    }

    // Update the file list state
    setFileList(info.fileList);
  };

  // Function to handle date changes
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = moment(e.target.value).format("YYYY-MM-DD"); // Convert input value to Date object
    setOpportunity((prevState) => ({
      ...prevState,
      endDate: selectedDate, // Update the endDate in the opportunity state
    }));
  };

  // Function to handle form submission
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(opportunity, { abortEarly: false });
      const data = { ...opportunity };
      data.endDate = moment(opportunity.endDate).format("YYYY-MM-DD");
      await dispatch(addOpportunity(opportunity)).then(() => {
        setIsLoading(false);
        dispatch(getAllOpportunities(opportunity.type));
      });
      setOpportunity({
        title: "",
        type: "",
        numberParticipants: 0,
        endDate: moment(opportunity.endDate).format("YYYY-MM-DD"),
        image: "",
      });
      setFileList([]);
      if (isLoading === false) {
        setIsModal(false);
      }

      props.onOpportunityAdded(opportunity.type);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof Opportunity, string>> = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path as keyof Opportunity] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [errors]);
  const opportunityTypes = ["التكوين", "التمويل"];

  return (
    <>
      <ButtonWithIcon
        onClick={() => {
          showModal();
        }}
        title="إضافة فرصة"
        imageSrc={icon}
      ></ButtonWithIcon>
      <Modal open={isModal} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1>إضافة فرصة</h1>
          <Divider className="cdis--divider" />
          <div className="cdis--modal-edit-opportunity-inputs">
            <div className="cids-input-error error-input1">
              <InputWithoutIconMedium
                id="title"
                type="text"
                className="cdis--modal-input1"
                value={opportunity.title}
                placeholder=" اسم الدورة"
                onChange={(event) => {
                  setOpportunity({
                    ...opportunity,
                    title: event.target.value,
                  });
                }}
              />
              {errors.title && <span>{errors.title}</span>}
            </div>
            <div className="cids-input-error error-select">
              <SelectSmall
                className="cdis--modal-select"
                selectPlaceholder=" نوع الفرصة"
                options={opportunityTypes}
                value={opportunity.type}
                onChange={(event) => {
                  setOpportunity({
                    ...opportunity,
                    type: event.target.value,
                  });
                }}
              />
              {errors.type && <span>{errors.type}</span>}
            </div>
            <div className="cids-input-error error-select">
              <InputWithoutIconMedium
                id="description"
                type="number"
                placeholder="  عدد المشاركين"
                value={opportunity.numberParticipants || ""}
                className="cdis--modal-input2"
                onChange={(event) => {
                  setOpportunity({
                    ...opportunity,
                    numberParticipants: Number(event.target.value),
                  });
                }}
              />
              {errors.numberParticipants && (
                <span>{errors.numberParticipants}</span>
              )}
            </div>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--modal-h4">تحميل صورة :</h4>
            <div className="cids-input-error">
              <Upload
                fileList={fileList}
                className="cdis--upload-file"
                name="image"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
              >
                <Button className="cdis--upload" icon={<UploadOutlined />}>
                  انقر لتحميل
                </Button>
              </Upload>
              {errors.image && <span>{errors.image}</span>}
            </div>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4>آخر أجل للمشاركة :</h4>

            <InputWithoutIconMedium
              name="licence_date"
              id="endDate"
              lang="fr-CA"
              value={opportunity.endDate}
              className="cdis--modal-edit-opportunity-input"
              placeholder="تاريخ"
              type="date"
              onChange={handleDateChange}
            />
          </div>
          <div className="cdis--modal-buttons">
            <ButtonSecondary
              onClick={() => {
                handleSubmit();
                setIsLoading(true);
              }}
              title="إضافة"
              loading={isLoading}
            />

            <ButtonOutlined
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddOpportunityModal;
