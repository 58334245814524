import React from "react";
import "../../styles/buttons.scss";

interface DashboardButtonOpportunitiesProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

const DashboardButtonOpportunities = ({
  ...props
}: DashboardButtonOpportunitiesProps) => {
  return (
    <button
      {...props}
      className={
        props.isActive
          ? `cdis--opportunities-button ${props.className ?? ""}`
          : `cdis--opportunities-button opacity-60 ${props.className ?? ""}`
      }
      onClick={props.onClick}
    >
      {props.title}
    </button>
  );
};

export default DashboardButtonOpportunities;
