import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { baseUrlApi } from "../../proxy";
import {
  CaseCdisInterface,
  CaseInterface,
} from "../../interfaces/case.interface";
import { ChangeEvent } from "react";
import { message } from "antd";

interface GetAllCasesArgs {
  page: number;
  search: string;
  selectSearch: ChangeEvent<HTMLSelectElement> | string;
}

axios.defaults.withCredentials = true;

// add cases
export const addCases = createAsyncThunk<
  AxiosResponse<any, any>,
  CaseInterface,
  { rejectValue: string }
>("case-source/add", async (cases: CaseInterface, { rejectWithValue }) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };
    const response = await axios.post(
      `${baseUrlApi}cases/add-case-invite`,
      cases,
      config
    );
    message.success("تم تسجيل الإحالة بنجاح");
    return response.data;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || "حدث خطأ اثناء تسجيل الإحالة";
    message.error(errorMessage);
    return rejectWithValue(errorMessage);
  }
});

// add cases as cdis agent
export const addCasesAsCdisAgent = createAsyncThunk<
  AxiosResponse<any, any>,
  CaseCdisInterface,
  { rejectValue: string }
>("case-agent/add", async (cases: CaseCdisInterface, { rejectWithValue }) => {
  try {
    const config = { headers: { "Content-Type": "application/json" } };

    let response = await axios.post(
      `${baseUrlApi}cases/add-case-cdis-agent`,
      cases,
      config
    );
    return response;
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.message || "حدث خطأ اثناء تسجيل الإحالة";
    message.error(errorMessage);
    return rejectWithValue(errorMessage);
  }
});

// get all cases
export const getAllCases = createAsyncThunk<
  AxiosResponse<any, any>,
  GetAllCasesArgs
>("case-source/getAll", async ({ page, search, selectSearch }) => {
  try {
    let response = await axios.get(
      `${baseUrlApi}cases/get-cases?page=${page}&search=${search}&selectFilter=${selectSearch}`
    );
    return response;
  } catch (error) {
    throw error;
  }
});

//Update cases
export const updateCases = createAsyncThunk(
  "cases/updateCases",
  async ({ id, cases }: { id: string; cases: any }) => {
    try {
      const response = await axios.post(
        `${baseUrlApi}cases/update-case-cdis-agent/${id}`,
        cases
      );
      message.success("تم قبول الإحالة");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.error(error);
      throw error;
    }
  }
);

//Delete cases
export const deleteCases = createAsyncThunk(
  "cases/deleteCases",
  async ({ id }: { id: string }) => {
    try {
      const response = await axios.delete(
        `${baseUrlApi}cases/delete-case-cdis/${id}`
      );
      message.success("تم حذف الإحالة");
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error(error.response?.data?.message || "An error occurred");
      } else {
        message.error("An unexpected error occurred");
      }
      console.error(error);
      throw error;
    }
  }
);
