import React, { useState } from "react";
import { Modal } from "antd";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import "../../../styles/modals.scss";

interface ModalProps {
  type: string;
  deletedItem: any;
  handleDelete: (item: any) => void;
}

const ConfirmDeleteModal = ({
  handleDelete,
  deletedItem,
  ...props
}: ModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <ButtonSecondary
        title="حذف"
        onClick={() => {
          showModal();
        }}
      />

      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1> تأكيد الحذف</h1>
          <h4> هل أنت متأكد أنك تريد حذف هذه {props.type}؟ </h4>
          <h4>تأكيد الحذف ؟</h4>

          <div className="cdis--modal-buttons">
            <ButtonOutlined
              onClick={() => {
                handleDelete(deletedItem);
                setIsModalOpen(false);
              }}
              title="تأكيد"
              
            />
            <ButtonSecondary
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmDeleteModal;
