import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import "../../../styles/modals.scss";
interface ModalProps {
  setIsModal: (value: boolean) => void;
  editState: any;
  handleUpdate: () => void;
}

const ModalConfirm = ({ editState, handleUpdate, ...props }: ModalProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleLoading = () => {
    handleUpdate();
    setIsLoading(false);
    if (isLoading === false) {
      handleCancel();
    }
  };
  return (
    <>
      <ButtonOutlined
        onClick={() => {
          showModal();
          props.setIsModal(false);
        }}
        title="تأكيد"
      />

      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1> تأكيد تعديل</h1>
          <h4>{editState?.title}</h4>

          <h4>تأكيد التعديل ؟</h4>

          <div className="cdis--modal-buttons">
            <ButtonOutlined
              onClick={() => {
                setIsLoading(true);
                handleLoading();
              }}
              title="تأكيد"
              loading={isLoading}
              property="loading-color"
            />
            <ButtonSecondary
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalConfirm;
