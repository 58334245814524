import React, { useEffect } from "react";
import "../../styles/cards.scss";
import EditOpportunityModal from "../../domains/dashboard/modals/EditOpportunityModal";
import ConfirmDeleteModal from "../../domains/dashboard/modals/ConfirmDeleteModal";
import { deleteOpportunity } from "../../apis/actions/opportunity.actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../apis/store";
import { Opportunity } from "../../interfaces/opportunity.types";
interface DetailsCardProps extends React.HTMLAttributes<HTMLDivElement> {
  opportunity: any;
  onClick: () => void;
}
const OpportunitiesDetailsCard = ({
  opportunity,
  ...props
}: DetailsCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleDeleteOpportunity = (opportunity: Opportunity) => {
    dispatch(deleteOpportunity(opportunity?.id));
  };
  return (
    <div className="cdis--details-card-container">
      <div onClick={() => props?.onClick()} className="cdis--details-card">
        <h6 className="cdis--details-card-title">{opportunity?.title}</h6>
        <div className="cdis--box-details-container">
          <p>عدد المشاركين</p>
          <span>
            <label>{opportunity?.candidates}</label>/
            {opportunity?.numberParticipants}
          </span>
        </div>
      </div>
      <div className="button-container">
        <EditOpportunityModal opportunity={opportunity} />
        <ConfirmDeleteModal
          type="الفرصة"
          deletedItem={opportunity}
          handleDelete={handleDeleteOpportunity}
        />
      </div>
    </div>
  );
};

export default OpportunitiesDetailsCard;
