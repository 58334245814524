import React from 'react';
import '../../styles/text.scss';

interface MyTextParagraphProps extends React.HTMLAttributes<HTMLParagraphElement> {
  title: string;
}

const TextParagraph = ({ title, ...props }: MyTextParagraphProps) => {
  return (
    <p {...props} className={`cdis--text-paragraph ${props.className ?? ''}`}>
      {title}
    </p>
  );
};

export default TextParagraph;
