import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkUserConnection } from "../apis/actions/auth.actions";
import { AppDispatch } from "../apis/store";

const ProtectedRoute = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { current, status } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (status === "idle") {
      dispatch(checkUserConnection());
    }
  }, [dispatch, status]);

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed" || !current) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
};

export default ProtectedRoute;
