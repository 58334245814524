import { createSlice } from "@reduxjs/toolkit";
import {
  addCases,
  addCasesAsCdisAgent,
  deleteCases,
  getAllCases,
  updateCases,
} from "../actions/case.actions";

/* Intiatlisation of initialeState */
const initialState = {
  case: {} as any,
  cases: [] as any,
  loading: false,
  error: null as unknown | null,
  headers: {},
};

/* case slice configuration */
export const caseSlice = createSlice({
  name: "cases",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* cases Reducer */
    builder.addCase(addCases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCases.fulfilled, (state, action) => {
      state.case = action.payload;
      state.loading = false;
    });
    builder.addCase(addCases.rejected, (state, action) => {
      state.case = {};
      state.loading = false;
      state.error = action.payload;
    });
    /* add cases as cdis agent Reducer */
    builder.addCase(addCasesAsCdisAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addCasesAsCdisAgent.fulfilled, (state, action) => {
      state.case = action.payload;
      state.loading = false;
    });
    builder.addCase(addCasesAsCdisAgent.rejected, (state, action) => {
      state.case = {};
      state.loading = false;
      state.error = action.payload;
    });
    /* get all cases */
    builder.addCase(getAllCases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCases.fulfilled, (state, action) => {
      state.cases = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllCases.rejected, (state, action) => {
      state.cases = [];
      state.loading = false;
      state.error = action.payload;
    });

    /* update cases */
    builder.addCase(updateCases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCases.fulfilled, (state, action) => {
      state.case = action.payload;
      state.loading = false;
    });
    builder.addCase(updateCases.rejected, (state, action) => {
      state.case = {};
      state.loading = false;
      state.error = action.payload;
    });

    /* delete cases */
    builder.addCase(deleteCases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCases.fulfilled, (state, action) => {
      state.case = action.payload;
      state.loading = false;
    });
    builder.addCase(deleteCases.rejected, (state, action) => {
      state.case = {};
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default caseSlice.reducer;
