import React from "react";
import "../../styles/partnersSection.scss";
import partner1 from "../../assets/images/partner-1.svg";
import associationHorizon from "../../assets/images/associationHorizon.svg";
import partner from "../../assets/images/partner-2.svg";
import partner3 from "../../assets/images/partner-3.svg";
import undp from "../../assets/images/UNDP.svg";
import partner4 from "../../assets/images/partner-4.svg";

const PartnersSection = () => {
  const tab = [partner1, associationHorizon, partner, partner3, undp, partner4];
  return (
    <section  className="cdis--partners-section-container">
      {tab.map((imagePath, index) => (
        <figure className="cdis--image-container">
          <img key={index} src={imagePath} alt="" />
        </figure>
      ))}
    </section>
  );
};

export default PartnersSection;
