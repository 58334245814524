import React, { useEffect, useState } from "react";
import "../../styles/perspectives.scss";
import { Table } from "antd";
import PaginationTable from "../../Biblio/PaginationTable";
import { Opportunity } from "../../interfaces/opportunity.types";
import { AppDispatch, RootState } from "../../apis/store";
import { useDispatch, useSelector } from "react-redux";
import { getAllCandidateByOpportunityId } from "../../apis/actions/candidate.actions";
interface OpportunityDetailsProps {
  opportunity: Opportunity; // Accept the opportunity as a prop
}
const OpportunityDetails = ({ opportunity }: OpportunityDetailsProps) => {
  const columns = [
    {
      title: "الاسم واللقب",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "العنوان",
      dataIndex: "adress",
      key: "adress",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "الحالة المدنية ",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "المستوى التعليمي",
      dataIndex: "level",
      key: "level",
    },
    {
      title: "التغطية الإجتماعية",
      dataIndex: "socialSecurity",
      key: "socialSecurity",
    },
  ];
  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(
      getAllCandidateByOpportunityId({ id: opportunity?.id, page: page })
    );
  }, [dispatch, opportunity?.id, page]);

  const selectedOpportunity = useSelector(
    (state: RootState) => state?.candidates?.allCandidates?.success
  );
  const totalPages = useSelector(
    (state: RootState) => state?.candidates?.allCandidates?.totalCases
  );
  const dataSource =
    selectedOpportunity &&
    selectedOpportunity.map((item: any, index: number) => ({
      key: index.toString(),
      name: item?.fullName,
      adress: item?.adress,
      phone: item?.phoneNumber,
    }));
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  return (
    <section className="cdis--opportunity-details-container">
      <div className="cdis--opportunity-details-header">
        <h1 className="cdis--opportunities-title">المنتفعين</h1>
        <h3 className="cdis--opportunities-subtitle">{opportunity?.title}</h3>
      </div>
      <div className="cdis--opportunity-details-table">
        <Table dataSource={dataSource} columns={columns} pagination={false} />
      </div>
      <PaginationTable
        current={page}
        defaultCurrent={1}
        total={totalPages}
        onChange={handlePageChange}
      />
    </section>
  );
};

export default OpportunityDetails;
