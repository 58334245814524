import React, { ReactElement } from "react";
import "../styles/cadreDashboard.scss";

interface CadreDashboardProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string | ReactElement;
  text?: string;
  title?: string;
  number?: number;
  children?: React.ReactNode;
}

const CadreDashboard = ({
  icon,
  text,
  number,
  children,
  title,
  ...props
}: CadreDashboardProps) => {
  return (
    <div className={`cdis--cadre-dashboard-container ${props.className ?? ""}`}>
      {title && <div className="cdis--cadre-dashboard-title">{title}</div>}
      <div className="cdis--cadre-dashboard-text-container">
        {icon && <div className="cdis--cadre-dashboard-icon">{icon}</div>}
        <span className="cdis--cadre-dashboard-text">{text}</span>
        {number !== undefined && (
          <div className="cdis--cadre-dashboard-number">{number}</div>
        )}
      </div>
      {children && (
        <div className="cdis--cadre-dashboard-content">{children}</div>
      )}
    </div>
  );
};

export default CadreDashboard;
