import React from "react";
import CadreText from "../../Biblio/CadreText";
import "../../styles/programmsSection.scss";
const ProgrammsSection = () => {
  const programmsTitle = [
    "برنامج الوقاية والتأهيل التربوي",
    "برنامج الاحاطة والوساطة العائلية",
    "برنامج مقاومة تشغيل الأطفال",
    "برنامج التعهد بالنساء والأطفال ضحايا العنف",
    "برنامج حماية الأطفال في نزاع مع القانون",
    "برنامج مقاومة ظاهرة التطرف العنيف",
    "برنامج العمل الاجتماعي بالشارع",
    "برنامج الإحاطة بالمسرحين من السجون",
  ];
  return (
    <div className="cdis--programms-section">
      {programmsTitle.map((title, index) => (
        <CadreText className="cdis--cader-content" title={title} />
      ))}
    </div>
  );
};

export default ProgrammsSection;
