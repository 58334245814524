import React from "react";
import "../../styles/cards.scss";
import { useNavigate } from "react-router-dom";
interface CardWithTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  urlImage: string;
  title: string;
  id: string;
}
const CardNewsWithTitle = ({ ...props }: CardWithTitleProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/news/${props?.id}`);
    };
  return (
    <div onClick={handleClick} className="cdis--card-with-title">
      <figure className="cdis--card-image">
        <img alt="" src={props.urlImage} />
      </figure>
      <div className="cdis--title-container">
        <h6>{props.title}</h6>
      </div>
    </div>
  );
};

export default CardNewsWithTitle;
