import React, { ChangeEvent } from "react";
import "../../styles/inputs.scss";

interface InputProps extends React.AllHTMLAttributes<HTMLInputElement> {
  id?: string;
  type: string;
  placeholder: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
const InputWithoutIconMediumLarge = ({ ...props }: InputProps) => {
  return (
    <input
      {...props}
      className={`cdis--input-common cdis--input-withoutIcon-mediumLarge ${
        props.className ?? ""
      }`}
      type={props.type}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  );
};

export default InputWithoutIconMediumLarge;
