import React from "react";
import "../../styles/buttons.scss";

interface ButtonTextBigProps {
  onClick: () => void;
  title: string;
  imageSrc: string;
}

const ButtonWithIcon = ({ title, onClick, imageSrc }: ButtonTextBigProps) => {
  return (
    <button className="cdis--button-text-icon" onClick={onClick}>
      <div className="button-text-icon-container">
        <img src={imageSrc} alt="button icon" />
        <span className="button-title">{title}</span>
      </div>
    </button>
  );
};

export default ButtonWithIcon;
