import React from "react";
import "../../styles/cards.scss";
import ConfirmDeleteModal from "../../domains/dashboard/modals/ConfirmDeleteModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../apis/store";
import EditNewModal from "../../domains/dashboard/modals/EditNewModal";
import { deleteNew } from "../../apis/actions/news.actions";
import { New } from "../../interfaces/new.types";
interface DetailsCardProps extends React.HTMLAttributes<HTMLDivElement> {
  annonce: any;
}
const NewDetailsCard = ({ annonce, ...props }: DetailsCardProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleDeleteNew = (annonce: New) => {
    dispatch(deleteNew(annonce?.id));
  };
  return (
    <div className="cdis--details-card-container ">
      <div className="cdis--details-card cdis--news-details-card">
        <h6 className="cdis--details-card-title">{annonce?.title}</h6>
        <div className="cdis--box-details-container">
          <p> {annonce?.description}</p>
        </div>
      </div>
      <div className="button-container">
        <EditNewModal annonce={annonce} />
        <ConfirmDeleteModal
          type="المستجد"
          deletedItem={annonce}
          handleDelete={handleDeleteNew}
        />
      </div>
    </div>
  );
};

export default NewDetailsCard;
