import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCasesOpportunity,
  getOpportunityByType,
  getStatistcsCases,
} from "../actions/dashboard.actions";

/* Intiatlisation of initialeState */
const initialState = {
  casesOpportunity: [] as any,
  casesStatistics: {} as any,
  opportunityStatistics: {} as any,
  loading: false,
  error: null as unknown | null,
  headers: {},
};

/* dashboard slice configuration */
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCasesOpportunity.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllCasesOpportunity.fulfilled, (state, action) => {
      state.casesOpportunity = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllCasesOpportunity.rejected, (state, action) => {
      state.casesOpportunity = [];
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getStatistcsCases.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStatistcsCases.fulfilled, (state, action) => {
      state.casesStatistics = action.payload;
      state.loading = false;
    });
    builder.addCase(getStatistcsCases.rejected, (state, action) => {
      state.casesStatistics = {};
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getOpportunityByType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOpportunityByType.fulfilled, (state, action) => {
      state.opportunityStatistics = action.payload;
      state.loading = false;
    });
    builder.addCase(getOpportunityByType.rejected, (state, action) => {
      state.opportunityStatistics = {};
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default dashboardSlice.reducer;
