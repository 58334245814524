import React from "react";
import NavigationMenu from "../../Biblio/Tabs/NavigationMenu";
import ButtonOutlined from "../../Biblio/Buttons/ButtonOutlined";
import ButtonPrimary from "../../Biblio/Buttons/ButtonPrimary";
import logo from "../../assets/logos/cedis-xsmall.svg";
import BurgerMenu from "./BurgerMenu";
import "../../styles/navbar.scss";
import { useNavigate } from "react-router-dom";
// Define the props interface for NavbarProps
interface NavbarProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}

const NavBar = ({ ...props }: NavbarProps) => {
  const navigate = useNavigate();
  return (
    <>
      <nav
        id="navbar"
        {...props}
        className={`cdis--navigation-bar ${props.className ?? ""}`}
      >
        <div className="cdis--navbar-list-container">
          <div className="cdis--navbar-buttons">
            <ButtonPrimary
              className="cdis--primary-btn"
              title="تسجيل الدخول"
              onClick={() => navigate("/login")}
            />
            <ButtonOutlined
              className="cdis--outlined-btn"
              title="إضافة حالة "
              onClick={() => navigate("/add-case")}
            />
          </div>
          <NavigationMenu />
        </div>
        <figure className="cdis--image-container">
          <img alt="logo " src={logo} />
        </figure>
        <BurgerMenu />
      </nav>
    </>
  );
};

export default NavBar;
