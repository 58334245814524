import { Pagination } from "antd";
import "../styles/pagination.scss";
import React from "react";

interface PaginationTableProps {
  defaultCurrent: number;
  total: number;
  onChange: (page: number) => void;
  current: number;
}

const PaginationTable = ({
  defaultCurrent,
  total,
  onChange,
  current,
}: PaginationTableProps) => {
  return (
    <Pagination
      className="cdis--pagination-table"
      defaultCurrent={defaultCurrent}
      total={total}
      current={current}
      responsive={true}
      onChange={onChange}
    />
  );
};

export default PaginationTable;
