import React, { ChangeEvent, useEffect, useState } from "react";
import SearchInput from "../../Biblio/SearchInput";
import { options } from "../../data/data";
import "../../styles/perspectives.scss";

import SelectItem from "../../Biblio/Selects/SelectItem";
import PaginationTable from "../../Biblio/PaginationTable";
import { Table } from "antd";
import DetailsModal from "./modals/DetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../apis/store";
import {
  deleteCases,
  getAllCases,
  updateCases,
} from "../../apis/actions/case.actions";
import { CaseInterface } from "../../interfaces/case.interface";
import DashboardButtonOpportunities from "../../Biblio/Buttons/DashboardButtonOpportunities";
import ButtonPrimary from "../../Biblio/Buttons/ButtonPrimary";
import { SectionLoader } from "../../Biblio/loaders/SectionLoader";

const Perspectives = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<
    ChangeEvent<HTMLSelectElement> | string
  >("");
  const [activeButton, setActiveButton] = useState<string>("المنظورين");

  const data = useSelector((state: any) => state?.cases?.cases?.data?.success);

  const totalPages = useSelector(
    (state: any) => state?.cases?.cases?.data?.totalCases
  );

  const [filterDataFalse, setFilterDataFalse] = useState<any[]>([]);
  const [filterDataTrue, setFilterDataTrue] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const falseData = data.filter(
        (item: any) => item.isDraft === false && item.image === null
      );
      const trueData = data.filter(
        (item: any) => item.isDraft === true && item.image === null
      );

      setFilterDataFalse(falseData);
      setFilterDataTrue(trueData);
    }
  }, [data]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
      dispatch(
        getAllCases({
          page: currentPage,
          search: searchTerm,
          selectSearch: selectedOption,
        })
      ).then(() => setLoading(false));
  }, [dispatch, currentPage, searchTerm, selectedOption]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const handleSearchSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };
const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleUpdate = async (data: any, newBooleanValue: boolean) => {
    const updatedData = { ...data, isDraft: newBooleanValue };

    try {
      await dispatch(updateCases({ id: data.id, cases: updatedData })).then(
        () => setIsLoading(false)
      );

      setFilterDataTrue((prev) =>
        prev.filter((item: any) => item.id !== data.id)
      );
      setFilterDataFalse((prev) => [...prev, updatedData]);
    } catch (error) {
      console.error("Failed to update case:", error);
    }
  };

  const handledelete = (data: any) => {
    try {
      dispatch(deleteCases({ id: data?.source?.id }));

      setFilterDataTrue((prev) =>
        prev.filter((item: any) => item?.id !== data?.id)
      );
    } catch (error) {
      console.error("Failed to update case:", error);
    }
  };

  const dataSource =
    filterDataFalse &&
    filterDataFalse.map((item: any, index: number) => ({
      id: item.id,
      fullNameConcerned: item.fullNameConcerned,
      phoneConcerned: item.phoneConcerned,
      CIN: item.CIN,
      subject: item.subject,
      levelScolaire: item.levelScolaire,
      socialCoverage: item.socialCoverage,
      source: item.source,
    }));

  const dataSourceTrue =
    filterDataTrue &&
    filterDataTrue.map((item: any, index: number) => ({
      id: item.id,
      fullNameConcerned: item.fullNameConcerned,
      phoneConcerned: item.phoneConcerned,
      CIN: item.CIN,
      subject: item.subject,
      levelScolaire: item.levelScolaire,
      isDraft: item.isDraft,
      socialCoverage: item.socialCoverage,
      source: item.source,
    }));

  const columns = [
    {
      title: "الاسم واللقب",
      dataIndex: "fullNameConcerned",
      key: "fullNameConcerned",
      width: "15%",
    },
    {
      title: "عدد ب ت و",
      dataIndex: "CIN",
      key: "CIN",
      width: "15%",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phoneConcerned",
      key: "phoneConcerned",
      width: "15%",
    },
    {
      title: "موضوع الإحالة",
      dataIndex: "subject",
      key: "subject",
      width: "15%",
    },
    {
      title: "المستوى التعليمي",
      dataIndex: "levelScolaire",
      key: "levelScolaire",
      width: "15%",
    },
    {
      title: "التغطية الإجتماعية",
      dataIndex: "socialCoverage",
      key: "socialCoverage",
      width: "15%",
    },
    {
      title: "المزيد",
      dataIndex: "more",
      key: "more",
      width: "10%",
      render: (text: any, record: CaseInterface) => (
        <DetailsModal data={record} />
      ),
    },
  ];

  const columnsTrue = [
    {
      title: "الاسم واللقب",
      dataIndex: "fullNameConcerned",
      key: "fullNameConcerned",
      width: "15%",
    },
    {
      title: "عدد ب ت و",
      dataIndex: "CIN",
      key: "CIN",
      width: "15%",
    },
    {
      title: "رقم الهاتف",
      dataIndex: "phoneConcerned",
      key: "phoneConcerned",
      width: "10%",
    },
    {
      title: "موضوع الإحالة",
      dataIndex: "subject",
      key: "subject",
      width: "15%",
    },
    {
      title: "المستوى التعليمي",
      dataIndex: "levelScolaire",
      key: "levelScolaire",
      width: "20%",
    },
    {
      title: "التغطية الإجتماعية",
      dataIndex: "socialCoverage",
      key: "socialCoverage",
      width: "20%",
    },
    {
      title: "المزيد",
      dataIndex: "more",
      key: "more",
      width: "10%",
      render: (text: any, record: CaseInterface) => (
        <DetailsModal data={record} />
      ),
    },
    {
      title: "الموافقة",
      dataIndex: "more",
      key: "more",
      width: "10%",
      render: (text: any, record: any) => (
        <span className="cdis--btn-perspective-container">
          <ButtonPrimary
            className="cdis--btn-perspective"
            title="موافقة"
            onClick={() => {
              setIsLoading(true)
              handleUpdate(record, false);
            }}
            loading={isLoading}
          />
          <ButtonPrimary
            className="cdis--btn-perspective-2"
            title="إلغاء"
            onClick={() => {
              handledelete(record);
            }}
          />
        </span>
      ),
    },
  ];

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
  };

  return (
    <div className="cdis--perspectives-container">
      <div className="cdis--perspectives-header">
        <h1 className="cdis--perspectives-title">المنظورين</h1>
        <div className="cdis--perspectives-box">
          <SearchInput placeholder="بحث" onSearch={handleSearch} />
          <SelectItem
            options={options}
            selectPlaceholder="أختر موضوع الإحالة"
            onChange={handleSearchSelect}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          marginTop: "1rem",
        }}
        className="cdis--opportunities-buttons"
      >
        <DashboardButtonOpportunities
          isActive={activeButton === "المنظورين"}
          onClick={() => handleButtonClick("المنظورين")}
          title="المنظورين"
        />
        <DashboardButtonOpportunities
          title={`(${filterDataTrue?.length})  قائمة الإنتظار`}
          className="cdis--button-opportunities-2"
          isActive={activeButton === "قائمة الإنتظار"}
          onClick={() => handleButtonClick("قائمة الإنتظار")}
        />
      </div>

      {activeButton === "المنظورين" ? (
        <>
          <div className="cdis--perspectives-table">
            {loading ? (
               <div className="cdis--loader-container"
        >
              <SectionLoader /></div>
            ) : (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            )}
          </div>

          <PaginationTable
            defaultCurrent={1}
            current={currentPage}
            total={totalPages}
            onChange={handlePageChange}
          />
        </>
      ) : (
        <>
          <div className="cdis--perspectives-table">
            {loading ? (
               <div className="cdis--loader-container"
        >
              <SectionLoader /></div>
            ) : (
            <Table
              dataSource={dataSourceTrue}
              columns={columnsTrue}
              pagination={false}
            />
            )}
          </div>
          <PaginationTable
            defaultCurrent={1}
            current={currentPage}
            total={totalPages}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default Perspectives;
