import React from "react";
import "../styles/cadre.scss";
import TextBold from "./Text/TextBold";
// Define the props interface for CadreTextProps
interface CadreTextProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  title: string;
}
const CadreText = ({ ...props }: CadreTextProps) => {
  return (
    <div {...props} className={`cdis--cadre ${props.className ?? ""}`}>
      <TextBold title={props.title} />
    </div>
  );
};

export default CadreText;
