import React from "react";
import "../../styles/buttons.scss";
import ButtonLoader from "../loaders/ButtonLoader";

interface MyButtonFadedProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  title: string;
  loading?: boolean;
}

const ButtonFaded = ({ title,loading, onClick, ...props }: MyButtonFadedProps) => {
  return (
    <button
      {...props}
      className={`cdis--button-faded ${props.className ?? ""}`}
      onClick={onClick}
    >
      {loading && <ButtonLoader />} {title}
    </button>
  );
};

export default ButtonFaded;
