import React, { useEffect, useState } from "react";
import ButtonWithIcon from "../../../Biblio/Buttons/ButtonWithIcon";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import { Button, Divider, Modal } from "antd";
import icon from "../../../assets/icons/add.svg";
import InputWithoutIconMedium from "../../../Biblio/Inputs/InputWithoutIconMedium";
import "../../../styles/modals.scss";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../apis/store";
import { addNew, getAllNews } from "../../../apis/actions/news.actions";
import { New } from "../../../interfaces/new.types";
import Upload, { RcFile } from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
const AddNewsModal = () => {
  const [news, setNews] = useState({
    title: "",
    description: "",
    image: "",
  });

  const validationSchema = Yup.object({
    title: Yup.string().required("يرجى إدخال اسم الدورة"),
    description: Yup.string().required("يرجى إدخال تعريف المستجد"),
    image: Yup.string().required("يرجى إدخال الصورة"),
  });
  const [isModal, setIsModal] = useState(false);
  const showModal = () => {
    setIsModal(true);
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModal(false);
  };
  const [errors, setErrors] = useState<Partial<Record<keyof New, string>>>({});
  const [isLoading, setIsLoading] = useState(false);
  // Use dispatch from Redux for state management
  const dispatch = useDispatch<AppDispatch>();
  const handleSubmit = async () => {
    try {
      await validationSchema.validate(news, { abortEarly: false });
      await dispatch(addNew(news)).then(() => {
        setIsLoading(false);
        dispatch(getAllNews());
      });
      setNews({
        title: "",
        description: "",
        image: "",
      });
      setFileList([]);
      if (isLoading === false) {
        setIsModal(false);
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof New, string>> = {};
        err.inner.forEach((error) => {
          if (error.path) {
            validationErrors[error.path as keyof New] = error.message;
          }
        });
        setErrors(validationErrors);
      }
    }
  };
  // State to keep track of the uploaded files
  const [fileList, setFileList] = useState<RcFile[]>([]);

  // Function to handle file uploads
  const handleFileUpload = (info: any) => {
    // Extract the file object from the upload event
    const file = info.file.originFileObj;

    // If there is a file, read it as a Data URL and update the state
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setNews((prevState) => ({
          ...prevState,
          image: reader.result as string, // Update the image in the opportunity state
        }));
      };
      reader.onerror = (error) => {
        console.error("خطأ في قراءة الملف", error); // Log any errors
      };
    }

    // Update the file list state
    setFileList(info.fileList);
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [errors]);
  return (
    <>
      <ButtonWithIcon
        onClick={() => {
          showModal();
        }}
        title="إضافة مستجد جديد"
        imageSrc={icon}
      />

      <Modal open={isModal} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1>إضافة مستجد جديد</h1>
          <Divider className="cdis--divider" />
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--new-label">العنوان :</h4>
            <div className="cids-input-error error-input1">
              <InputWithoutIconMedium
                className="cdis--modal-edit-opportunity-input"
                placeholder="العنوان ..."
                type="text"
                value={news.title}
                onChange={(event) => {
                  setNews({
                    ...news,
                    title: event.target.value,
                  });
                }}
              />
              {errors.title && <span>{errors.title}</span>}
            </div>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container cdis--edit-news-image">
            <h4 className="cdis--new-label">تحميل صورة :</h4>
            <div className="cids-input-error">
              <Upload
                fileList={fileList}
                className="cdis--upload-file"
                name="image"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
              >
                <Button
                  className="cdis--upload"
                  id="cdis--button-upload"
                  icon={<UploadOutlined />}
                >
                  انقر لتحميل
                </Button>
              </Upload>
              {errors.image && <span>{errors.image}</span>}
            </div>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--new-label">التعريف :</h4>
            <div className="cids-input-error error-input1">
              <textarea
                className="cdis--new-textarea"
                placeholder="التعريف ..."
                value={news.description}
                onChange={(event) => {
                  setNews({
                    ...news,
                    description: event.target.value,
                  });
                }}
              />
              {errors.description && <span>{errors.description}</span>}
            </div>
          </div>
          <div className="cdis--modal-buttons">
            <ButtonSecondary
              onClick={() => {
                handleSubmit();
                setIsLoading(true);
              }}
              title="إضافة"
              loading={isLoading}
            />

            <ButtonOutlined
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddNewsModal;
