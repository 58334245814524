import React from "react";
import Image from "../../assets/images/banner.svg";
import "../../styles/heroSection.scss";
// Define the props interface for HeroSectionProps
interface HeroSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
}
const HeroSection = ({ ...props }: HeroSectionProps) => {
  return (
    <>
      <figure
        id="accueil"
        {...props}
        className={`cdis--hero-section-container ${props.className ?? ""}`}
      >
        <img className="cdis--hero-section-image" alt="hero" src={Image} />
      </figure>
    </>
  );
};

export default HeroSection;
