import React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import "../styles/searchInput.scss";

const { Search } = Input;
const customSuffix = <SearchOutlined />;

interface SearchInputProps {
  onSearch: (value: string) => void;
  placeholder: string;
}

const SearchInput = ({ ...props }: SearchInputProps) => (
  <Search
    placeholder="... بحث"
    onSearch={props.onSearch}
    suffix={customSuffix}
    allowClear
    className="cdis--search-input"
  />
);

export default SearchInput;
