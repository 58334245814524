import React from "react";
import { Steps } from "antd";
import "../styles/steps.scss";

interface Step {
  title: JSX.Element;
  disabled?: boolean;
}

interface StepsProps {
  current: number;
  steps: Step[];
}

const StepsComponent = ({ ...props }: StepsProps) => {
  return (
    <div className="custom-steps-container">
      <Steps
        className="cdis--steps-component"
        size="small"
        current={props.current}
        direction="horizontal"
        responsive={true}
      >
        {props.steps.map((step, index) => (
          <Steps.Step key={index} title={step.title} disabled={step.disabled} />
        ))}
      </Steps>
    </div>
  );
};

export default StepsComponent;
