import React from "react";
import "../../styles/text.scss";

interface MyTextPrimaryProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
}

const TextPrimary = ({ ...props }: MyTextPrimaryProps) => {
  return (
    <span className={`cdis--text-primary ${props.className}`}>
      {props.title}
    </span>
  );
};

export default TextPrimary;
