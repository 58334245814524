import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  addCandidate,
  getAllCandidateByOpportunityId,
} from "../actions/candidate.actions";
import { Candidate } from "../../interfaces/candidate.types";

interface CandidateState {
  candidate: Candidate;
  loading: boolean;
  error: string | null;
  allCandidates: {
    success: Candidate[];
    totalCases: number;
  };
}

const initialState: CandidateState = {
  candidate: {} as Candidate,
  allCandidates: { success: [], totalCases: 0 },
  loading: false,
  error: null,
};

const candidateSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCandidate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addCandidate.fulfilled,
        (state, action: PayloadAction<Candidate>) => {
          state.loading = false;
          state.candidate = action.payload;
        }
      )
      .addCase(addCandidate.rejected, (state, action) => {
        state.loading = false;
        state.candidate = {} as Candidate;
        state.error = action.error.message || "Failed to add Candidate";
      })

      // Handle getAllOpportunitiesPublic actions
      .addCase(getAllCandidateByOpportunityId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCandidateByOpportunityId.fulfilled, (state, action) => {
        state.loading = false;
        state.allCandidates = action.payload;
      })
      .addCase(getAllCandidateByOpportunityId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch";
      });
  },
});

export default candidateSlice.reducer;
