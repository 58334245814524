import React from "react";
import { Divider } from "antd";
import "../../styles/sectionTitle.scss";
// Define the props interface for SectionTitleProps
interface SectionTitleProps extends React.HTMLAttributes<HTMLDivElement> {
  id?: string;
  title: string;
}
const SectionTitle = ({ ...props }: SectionTitleProps) => {
  return (
    <div {...props} className="cdis--section-title-container">
      <Divider
        className={`cdis--title-section-divider ${props.className ?? ""}`}
      />
      <h4 className="cdis--title">{props.title}</h4>
      <Divider
        className={`cdis--title-section-divider ${props.className ?? ""}`}
      />
    </div>
  );
};

export default SectionTitle;
