import React, { useState, useEffect } from "react";
import OportunityCard from "../../Biblio/cards/OportunityCard";
import "../../styles/opportunities.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../apis/store";
import { getAllOpportunitiesPublic } from "../../apis/actions/opportunity.actions";

const OpportunitiesSection = () => {
  const [startIndex, setStartIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const opportunities = useSelector(
    (state: RootState) => state?.opportunities?.opportunitiesPublic
  );

  // Effect to handle automatic sliding of cards every 5 seconds
  useEffect(() => {
    if (!modalOpen) {
      const interval = setInterval(() => {
        triggerAnimation(() => {
          setStartIndex((prevIndex) =>
            prevIndex < opportunities?.length - 4 ? prevIndex + 4 : 0
          );
        });
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [opportunities?.length, modalOpen]);

  // Function to handle animation
  const triggerAnimation = (callback: any) => {
    setAnimating(true);
    setTimeout(() => {
      callback();
      setAnimating(false);
    }, 500);
  };

  // Function to handle clicking the previous button
  const handlePrev = () => {
    triggerAnimation(() => {
      setStartIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 4 : opportunities?.length - 4
      );
    });
  };

  // Function to handle clicking the next button
  const handleNext = () => {
    triggerAnimation(() => {
      setStartIndex((prevIndex) =>
        prevIndex < opportunities?.length - 4 ? prevIndex + 4 : 0
      );
    });
  };

  // Calculate the total number of buttons required based on opportunities? length
  const buttonCount = Math.ceil(opportunities?.length / 4);

  // Handle clicking on a specific button to navigate to the corresponding card set
  const handleClickStep = (step: any) => {
    triggerAnimation(() => {
      const targetIndex = step * 4;
      setStartIndex(targetIndex);
    });
  };

  // Handle modal open/close
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  // Fetch opportunities on component mount
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAllOpportunitiesPublic());
    };
    fetchData();
  }, [dispatch]);
  return (
    <section className="cdis--opportunities-section">
      <div className="cdis--buttons-container">
        <button onClick={handlePrev}>
          <ArrowLeftOutlined />
        </button>
        <div className="cdis--steps-container">
          {Array.from(Array(buttonCount).keys()).map((step) => (
            <div
              key={step}
              className={
                Math.floor(startIndex / 4) === step
                  ? "cdis--steps-selected"
                  : "cdis--steps-unselected"
              }
              onClick={() => handleClickStep(step)}
            ></div>
          ))}
        </div>
        <button onClick={handleNext}>
          <ArrowRightOutlined />
        </button>
      </div>
      <div
        className={`cdis--opportunities-section-content ${
          animating ? "fade-out" : "fade-in"
        }`}
      >
        {opportunities &&
          opportunities.length > 0 &&
          opportunities
            ?.slice(startIndex, startIndex + 4)
            ?.map((opportunity, index) => (
              <OportunityCard
                key={startIndex + index}
                opportunity={opportunity}
                onOpenModal={handleModalOpen} // Pass modal open handler to the card
                onCloseModal={handleModalClose} // Pass modal close handler to the card
              />
            ))}
      </div>
    </section>
  );
};

export default OpportunitiesSection;
