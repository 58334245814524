import React from 'react';
import CarousselContent from './CarousselContent';
import carousselImage from '../../assets/images/carrouselImage.svg';
import '../../styles/caroussel.scss';
const CarousselContainer = () => {
  return (
    <div  className="cdis--caroussel-container">
      <CarousselContent />
      <figure className="cdis--image-container">
        <img alt="" src={carousselImage} />
      </figure>
    </div>
  );
};

export default CarousselContainer;
