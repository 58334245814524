import React, { ChangeEvent } from "react";
import "../../styles/selects.scss";

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  id?: string;
  options: string[];
  selectPlaceholder: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}
const SelectLarge = ({ ...props }: SelectProps) => {
  return (
    <select
      {...props}
      className={`cdis--select-common cdis--select-large ${
        props.className ?? ""
      }`}
      onChange={props.onChange}
    >
      <option value="" disabled selected hidden>
        {props.selectPlaceholder}
      </option>
      {props.options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
};

export default SelectLarge;
