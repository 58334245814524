import React, { ChangeEvent } from 'react';
import '../../styles/inputs.scss';

interface InputProps extends React.AllHTMLAttributes<HTMLInputElement> {
  id?: string;
  type: string;
  placeholder: string;
  icon: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const InputWithIcon = ({ ...props }: InputProps) => {
  return (
    <div className="container-input-with-icon">
      <input
        {...props}
        className={`cdis--input-common input-with-icon ${
          props.className ?? ""
        }`}
        type={props.type}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      <img alt="" src={props.icon} />
    </div>
  );
};

export default InputWithIcon;


