import React from 'react';
import '../../styles/loaders.scss';
interface SectionLoaderInterface extends React.HTMLAttributes<HTMLDivElement> {}
export const SectionLoader = ({ ...props }: SectionLoaderInterface) => {
  return (
    <div className={`${props.className ?? ''}`}>
      <div {...props} className={`section-loader ${props.className ?? ''}`}></div>
    </div>
  );
};
