import React from "react";
import "../../styles/cards.scss";
import CandidateModal from "../../components/modals/CandidateModal";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  opportunity: any;
  onOpenModal?: () => void;
  onCloseModal?: () => void;
}
const OportunityCard = ({ opportunity, ...props }: CardProps) => {
  return (
    <div {...props} className={`cdis--card-container ${props.className ?? ""}`}>
      <figure className="cdis--card-image">
        <img alt="" src={opportunity?.image} />
      </figure>
      <div className="cdis--description-container">
        <h6>{opportunity?.title}</h6>
        <div className="cdis--box-container">
          <p>أخر أجل للتسجيل</p>
          <span>
            <label>{opportunity?.endDate}</label>
          </span>
        </div>
      </div>
      <CandidateModal
        opportunity={opportunity}
        onOpenModal={props.onOpenModal}
        onCloseModal={props.onCloseModal}
      />
    </div>
  );
};

export default OportunityCard;
