import React from "react";
import "../../styles/buttons.scss";
import ButtonLoader from "../loaders/ButtonLoader";

interface ButtonPrimaryProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  title: string;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonPrimary = ({
  title,
  loading,
  onClick,
  ...props
}: ButtonPrimaryProps) => {
  return (
    <button
      {...props}
      className={`cdis--button-primary ${props.className ?? ""}`}
      onClick={onClick}
    >
      {title}
      {loading && <ButtonLoader />}
    </button>
  );
};

export default ButtonPrimary;
