import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { baseUrlApi } from "../../proxy";

axios.defaults.withCredentials = true;

// get all cases opportunity
export const getAllCasesOpportunity = createAsyncThunk<AxiosResponse<any, any>>(
  "cases-opportunity/getAll",
  async () => {
    try {
      let response = await axios.get(
        `${baseUrlApi}dashbaord/all-opportunities-cases-dashboard`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// get statistcs cases
export const getStatistcsCases = createAsyncThunk<AxiosResponse<any, any>>(
  "cases-statistcs/getAll",
  async () => {
    try {
      let response = await axios.get(
        `${baseUrlApi}dashbaord/all-cases-dashboard`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

// get statistcs cases
export const getOpportunityByType = createAsyncThunk<AxiosResponse<any, any>>(
  "opportunity-statistcs/getAll",
  async () => {
    try {
      let response = await axios.get(
        `${baseUrlApi}dashbaord/all-opportunities-dashboard`
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
