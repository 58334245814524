import { createSlice } from "@reduxjs/toolkit";
import {
  checkUserConnection,
  loginUser,
  logoutUser,
} from "../actions/auth.actions";

/* Intiatlisation of initialeState */
const initialState = {
  user: {} as any,
  current: {} as any,
  loading: false as boolean,
  isAuthenticated: false as boolean,
  status: "idle" as string,
  error: null as unknown | null,
};

/* auth slice configuration */
export const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    /* Login Reducer */
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
      state.isAuthenticated = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.isAuthenticated = true;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.user = {};
      state.loading = false;
      state.isAuthenticated = false;
      state.error = action.payload;
    });
    /* check user connection Reducer */
    builder.addCase(checkUserConnection.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(checkUserConnection.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.current = action.payload;
    });
    builder.addCase(checkUserConnection.rejected, (state, action) => {
      state.status = "failed";
      state.current = {};
      state.error = action.payload;
    });
    /* Logout Reducer */
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, action) => {
      state.loading = false;
      state.current = {};
      state.isAuthenticated = false;
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = false;
      state.current = {};
      state.error = action.payload;
    });
  },
});

export default authSlice.reducer;
