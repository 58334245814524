import React, { useEffect, useState } from "react";
import "../../styles/formInscrit.scss";
import InputWithoutIconExtralarge from "../../Biblio/Inputs/InputWithoutIconExtralarge";
import { Button, Col, Row } from "antd";
import Upload from "antd/es/upload";
import { UploadOutlined } from "@ant-design/icons";
import { CaseInterface } from "../../interfaces/case.interface";
import * as Yup from "yup";

interface Props {
  selectedComponent: string;
  setDisabled: (value: boolean) => void;
  updateCases: (value: any) => void;
  data: any;
}
const FirstStep = ({
  setDisabled,
  data,
  updateCases,
  selectedComponent,
}: Props) => {
  const [cases, setCases] = useState<CaseInterface>({
    fullNameSource: data.fullNameSource,
    status: data.status,
    email: data.email,
    phoneSource: data.phoneSource,
    image: data.image,
  });

  const [errors, setErrors] = useState<any>({});
  const validationSchema = Yup.object({
    fullNameSource: Yup.string().required("يرجى إدخال الاسم واللقب"),
    status: Yup.string().required("يرجى إدخال الصفة"),
    phoneSource: Yup.number()
      .test(
        "is-required",
        "يرجى إدخال رقم الهاتف",
        (value: any) => value !== undefined && value !== 0
      )
      .test(
        "is-valid-number",
        "رقم الهاتف غير صحيح",
        (value: any) =>
          !value ||
          (!isNaN(Number(value)) &&
            Number(value) > 0 &&
            Number.isInteger(Number(value)))
      )
      .transform((value: any) => (isNaN(value) ? undefined : value))
      .nullable(),
    email: Yup.string()
      .email("يجب أن يكون البريد الإلكتروني صالحًا")
      .required("الرجاء التأكد من صحة البريد الإلكتروني"),
    ...(selectedComponent === "multiple" && {
      image: Yup.string().required("يرجى إدخال الصورة"),
    }),
  });

  const handleFileUpload = (info: any) => {
    const file = info.file.originFileObj;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setCases((prevState: any) => ({
          ...prevState,
          image: reader.result,
        }));
      };
      reader.onerror = (error) => {
        console.error("خطأ في قراءة الملف", error); // Log any errors
      };
    }
  };

  useEffect(() => {
    toVerifyData();
  }, [cases]);
  console.log(setDisabled);
  const toVerifyData = async () => {
    try {
      await validationSchema.validate(cases, {
        abortEarly: false,
      });
      setErrors({});
      setDisabled(false);
      updateCases(cases);
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors: Partial<Record<keyof CaseInterface, string>> =
          {};
        err.inner.forEach((error: any) => {
          if (error.path) {
            validationErrors[error.path as keyof CaseInterface] = error.message;
          }
        });
        setErrors(validationErrors);
        setDisabled(true);
      }
    }
  };
  return (
    <>
      <Row justify={"space-between"} gutter={48}>
        <Col
          xxl={12}
          xl={12}
          md={12}
          lg={12}
          xs={24}
          className="cdis--mt-3"
          style={{ direction: "rtl" }}
        >
          <InputWithoutIconExtralarge
            type="text"
            placeholder="الهاتف"
            value={cases.phoneSource || ""}
            onChange={(e) =>
              setCases({ ...cases, phoneSource: Number(e.target.value) })
            }
          />
          {errors.phoneSource && (
            <span style={{ color: "red" }}>{errors.phoneSource}</span>
          )}
        </Col>
        <Col
          xxl={12}
          xl={12}
          md={12}
          lg={12}
          xs={24}
          className="cdis--mt-3"
          style={{ direction: "rtl" }}
        >
          <InputWithoutIconExtralarge
            type="text"
            placeholder="الاسم واللقب"
            value={cases.fullNameSource || ""}
            onChange={(e) =>
              setCases({ ...cases, fullNameSource: e.target.value })
            }
          />
          {errors.fullNameSource && (
            <span style={{ color: "red" }}>{errors.fullNameSource}</span>
          )}
        </Col>
      </Row>
      <Row justify={"space-between"} gutter={48}>
        <Col
          xxl={12}
          xl={12}
          md={12}
          lg={12}
          xs={24}
          className="cdis--mt-3"
          style={{ direction: "rtl" }}
        >
          <InputWithoutIconExtralarge
            type="email"
            placeholder="البريد الإلكتروني"
            value={cases.email || ""}
            onChange={(e) => setCases({ ...cases, email: e.target.value })}
          />
          {errors.email && (
            <span style={{ color: "red" }} className="error-message">
              {errors.email}
            </span>
          )}
        </Col>
        <Col
          xxl={12}
          xl={12}
          md={12}
          lg={12}
          xs={24}
          className="cdis--mt-3"
          style={{ direction: "rtl" }}
        >
          <InputWithoutIconExtralarge
            type="text"
            placeholder="الصفة"
            value={cases.status || ""}
            onChange={(e) => setCases({ ...cases, status: e.target.value })}
          />
          {errors.status && (
            <span style={{ color: "red" }}>{errors.status}</span>
          )}
          {selectedComponent === "multiple" && (
            <>
              <Upload
                className="cdis--upload-file"
                name="image"
                onChange={handleFileUpload}
                accept=".png,.jpg,.jpeg"
              >
                <Button className="cdis--upload" icon={<UploadOutlined />}>
                  انقر لتحميل
                </Button>
              </Upload>
              {errors.image && (
                <span style={{ color: "red" }}>{errors.image}</span>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default FirstStep;
