import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Opportunity } from "../../interfaces/opportunity.types";
import {
  addOpportunity,
  deleteOpportunity,
  getAllOpportunities,
  getAllOpportunitiesPublic,
 
  updateOpportunity,
} from "../actions/opportunity.actions";

interface OpportunityState {
  opportunitie: Opportunity;
  opportunities: {
    success: Opportunity[];
  };
  opportunitiesPublic: Opportunity[] ;
  loading: boolean;
  error: string | null;
}

const initialState: OpportunityState = {
  opportunitie: {} as Opportunity,
  opportunities: { success: [] },
  opportunitiesPublic: [],
  loading: false,
  error: null,
};

const opportunitySlice = createSlice({
  name: "opportunities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        addOpportunity.fulfilled,
        (state, action: PayloadAction<Opportunity>) => {
          state.loading = false;
          state.opportunitie = action.payload;
        }
      )
      .addCase(addOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.opportunitie = {} as Opportunity;
        state.error = action.error.message || "Failed to add opportunity";
      })

      // Handle getAllOpportunities actions
      .addCase(getAllOpportunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllOpportunities.fulfilled,
        (state, action: PayloadAction<{ success: Opportunity[] }>) => {
          state.loading = false;
          state.opportunities = action.payload;
        }
      )
      .addCase(getAllOpportunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch opportunities";
      })
      // Handle getAllOpportunitiesPublic actions
      .addCase(getAllOpportunitiesPublic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getAllOpportunitiesPublic.fulfilled,
        (state, action: PayloadAction<Opportunity[]>) => {
          state.loading = false;
          state.opportunitiesPublic = action.payload;
        }
      )
      .addCase(getAllOpportunitiesPublic.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch public opportunities";
      })
      
      // Handle deleteOpportunity actions
      .addCase(deleteOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        deleteOpportunity.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.loading = false;
          state.opportunities.success = state.opportunities.success.filter(
            (opportunity) => opportunity.id !== action.payload
          );
        }
      )
      .addCase(deleteOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to delete opportunity";
      })

      // Handle updateOpportunity actions
      .addCase(updateOpportunity.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        updateOpportunity.fulfilled,
        (state, action: PayloadAction<Opportunity>) => {
          state.loading = false;
          state.opportunities.success = state?.opportunities?.success.map(
            (opportunity) =>
              opportunity.id === action.payload.id
                ? action.payload
                : opportunity
          );
        }
      )
      .addCase(updateOpportunity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to update opportunity";
      });
  },
});

export default opportunitySlice.reducer;
