import React, { useEffect, useState } from "react";
import "../../styles/opportunities.scss";
import DashboardButtonOpportunities from "../../Biblio/Buttons/DashboardButtonOpportunities";
import OpportunitiesDetailsCard from "../../Biblio/cards/OpportunitiesDetailsCard";
import { Divider } from "antd";
import OpportunityDetails from "./OpportunityDetails";
import AddOpportunityModal from "./modals/AddOpportunityModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../apis/store";
import { getAllOpportunities } from "../../apis/actions/opportunity.actions";
import { Opportunity } from "../../interfaces/opportunity.types";
import ButtonLoader from "../../Biblio/loaders/ButtonLoader";
import { SectionLoader } from "../../Biblio/loaders/SectionLoader";
const Opportunities = () => {
  const dispatch = useDispatch<AppDispatch>();
  const allopportunities = useSelector(
    (state: RootState) => state?.opportunities?.opportunities
  );
  const opportunities = allopportunities?.success;

  const [activeButton, setActiveButton] = useState<string>("التكوين");
  //handle button click function
  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    setSelectedCard(null);
    dispatch(getAllOpportunities(buttonName));
  };
  const [selectedCard, setSelectedCard] = useState<Opportunity | null>(null);
  const handleCardClick = (card: Opportunity) => {
    setSelectedCard(card);
  };
 const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
     
         dispatch(getAllOpportunities(activeButton)).then(() => {
           setIsLoading(false);
         });
     
     
    };
    fetchData();
  }, [activeButton, dispatch]);

  // Function to handle opportunity addition
  const handleOpportunityAdded = (opportunityType: string) => {
    setActiveButton(opportunityType);
  };

  return (
    <div className="cdis--dashboard-opportunities-container">
      <div className="cdis--dashboard-opportunities-header">
        <h1 className="cdis--opportunities-title">الفرص</h1>
        <AddOpportunityModal
          onOpportunityAdded={handleOpportunityAdded}
          activeButton={activeButton}
        />
      </div>
      <div className="cdis--opportunities-buttons">
        <DashboardButtonOpportunities
          isActive={activeButton === "التكوين"}
          onClick={() => handleButtonClick("التكوين")}
          title="التكوين"
        />
        <DashboardButtonOpportunities
          title="التمويل"
          className="cdis--button-opportunities-2"
          isActive={activeButton === "التمويل"}
          onClick={() => handleButtonClick("التمويل")}
        />
      </div>
      {selectedCard ? (
        <OpportunityDetails opportunity={selectedCard} />
      ) : isLoading ? (
        <SectionLoader />
      ) : (
        <div
          className={
            opportunities.length > 0
              ? "cdis--opportunities-cards-container"
              : "cdis--no-message-container"
          }
        >
          {opportunities.length > 0 ? (
            opportunities.map((opportunity, index: number) => (
              <React.Fragment key={index}>
                <OpportunitiesDetailsCard
                  opportunity={opportunity}
                  onClick={() => handleCardClick(opportunity)}
                />
                {index < opportunities.length - 1 && (
                  <Divider className="cdis--divider-opportunities" />
                )}
              </React.Fragment>
            ))
          ) : (
            <div className="cdis--no-opportunities-message">
              لا توجد فرص يمكنك إضافتها
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Opportunities;
