import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { baseUrlApi } from "../../proxy";
import { UserLogin } from "../../pages/Login/Login";

axios.defaults.withCredentials = true;

// Login
export const loginUser = createAsyncThunk<AxiosResponse<any, any>, UserLogin>(
  "login/auth",
  async (userLogin: UserLogin) => {
    try {
      const config = {
        headers: { "Content-Type": "application/json" },
      };

      let response = await axios.post(
        `${baseUrlApi}auth/login`,
        userLogin,
        config
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

// Check user
export const checkUserConnection = createAsyncThunk<AxiosResponse<any, any>>(
  "auth/checkUserConnection",
  async (_) => {
    try {
      const response = await axios.get(`${baseUrlApi}auth/current`);
      return response.data.success;
    } catch (error) {
      throw error;
    }
  }
);

// Logout
export const logoutUser = createAsyncThunk<AxiosResponse<any, any>>(
  "auth/logout",
  async () => {
    try {
      const response = await axios.post(`${baseUrlApi}auth/logout`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
