import React, { useEffect, useState } from "react";
import { Button, Divider, Modal, Upload } from "antd";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import InputWithoutIconMedium from "../../../Biblio/Inputs/InputWithoutIconMedium";
import ButtonSecondary from "../../../Biblio/Buttons/ButtonSecondary";
import "../../../styles/modals.scss";
import ModalConfirm from "./ModalConfirm";
import { UploadOutlined } from "@ant-design/icons";
import SelectSmall from "../../../Biblio/Selects/SelectSmall";
import { UploadFile } from "antd/es/upload";
import { Opportunity } from "../../../interfaces/opportunity.types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../apis/store";
import {
  getAllOpportunities,
  updateOpportunity,
} from "../../../apis/actions/opportunity.actions";

interface ModalProps {
  opportunity: Opportunity;
}

const EditOpportunityModal = ({ opportunity, ...props }: ModalProps) => {
  const [isModal, setIsModal] = useState(false);
  const [editOpportunity, setEditOpportunity] = useState<Opportunity>({
    id: opportunity?.id,
    title: opportunity?.title,
    type: opportunity?.type,
    numberParticipants: opportunity?.numberParticipants,
    endDate: opportunity?.endDate,
    image: opportunity?.image,
  });

  useEffect(() => {
    setEditOpportunity({
      id: opportunity?.id,
      title: opportunity?.title,
      type: opportunity?.type,
      numberParticipants: opportunity?.numberParticipants,
      endDate: opportunity?.endDate,
      image: opportunity?.image,
    });
  }, [opportunity]);

  const showModal = () => {
    setIsModal(true);
  };

  const handleCancel = () => {
    setIsModal(false);
  };
  // State to keep track of the uploaded files
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  // Function to handle file uploads
  useEffect(() => {
    if (editOpportunity.image) {
      setFileList([
        {
          uid: "-1", // Unique identifier for the file
          name: "image.jpg", // Name of the file
          status: "done", // Status of the file
          url: editOpportunity?.image, // URL for preview
        } as UploadFile,
      ]);
    }
  }, [editOpportunity.image]);
  const handleFileUpload = (info: any) => {
    // Extract the file object from the upload event
    const file = info.file.originFileObj;

    // If there is a file, read it as a Data URL and update the state
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setEditOpportunity((prevState) => ({
          ...prevState,
          image: reader.result as string, // Update the image in the opportunity state
        }));
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error); // Log any errors
      };
    }

    // Update the file list state
    setFileList(info.fileList);
  };
  const opportunityTypes = ["التكوين", "التمويل"];
  // Function to handle date changes
  // Ensure endDate is a Date object
  const formattedEndDate =
    editOpportunity.endDate instanceof Date
      ? editOpportunity.endDate.toISOString().substring(0, 10)
      : new Date(editOpportunity.endDate).toISOString().substring(0, 10);
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(e.target.value); // Convert input value to Date object
    setEditOpportunity((prevState) => ({
      ...prevState,
      endDate: selectedDate, // Update the endDate in the opportunity state
    }));
  };

  // Use dispatch from Redux for state management
  const dispatch = useDispatch<AppDispatch>();
  const handleUpdateOpportunity = async () => {
    dispatch(
      updateOpportunity({ id: opportunity?.id, opportunity: editOpportunity })
    ).then(() => {
      dispatch(getAllOpportunities(editOpportunity?.type));
    });
     
  };

  return (
    <>
      <ButtonOutlined
        onClick={showModal}
        title="تعديل"
        
      />
      <Modal open={isModal} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container">
          <h1>تعديل</h1>
          <h4>{opportunity?.title}</h4>
          <Divider className="cdis--divider" />
          <div className="cdis--modal-edit-opportunity-inputs">
            <InputWithoutIconMedium
              id="title"
              type="text"
              placeholder="العنوان"
              className="cdis--modal-input1"
              value={editOpportunity?.title}
              onChange={(event) => {
                setEditOpportunity({
                  ...opportunity,
                  title: event.target.value,
                });
              }}
            />
            <SelectSmall
              className="cdis--modal-select"
              selectPlaceholder=" نوع الفرصة"
              options={opportunityTypes}
              value={editOpportunity?.type}
              onChange={(event) => {
                setEditOpportunity({
                  ...opportunity,
                  type: event.target.value,
                });
              }}
            />
            <InputWithoutIconMedium
              id="description"
              type="number"
              placeholder="عدد المشاركين"
              className="cdis--modal-input2"
              value={editOpportunity?.numberParticipants || ""}
              onChange={(event) => {
                setEditOpportunity({
                  ...opportunity,
                  numberParticipants: Number(event.target.value),
                });
              }}
            />
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4 className="cdis--modal-h4">تحميل صورة :</h4>

            <Upload
              fileList={fileList}
              className="cdis--upload-file"
              name="image"
              accept=".png,.jpg,.jpeg"
              onChange={handleFileUpload}
            >
              <Button className="cdis--upload" icon={<UploadOutlined />}>
                انقر لتحميل
              </Button>
            </Upload>
          </div>
          <div className="cdis--modal-edit-opportunity-inputs-container">
            <h4>آخر أجل للمشاركة :</h4>
            <InputWithoutIconMedium
              value={formattedEndDate}
              className="cdis--modal-edit-opportunity-input"
              placeholder="تاريخ"
              type="date"
              onChange={handleDateChange}
            />
          </div>
          <div className="cdis--modal-buttons">
            <ModalConfirm
              editState={editOpportunity}
              handleUpdate={handleUpdateOpportunity}
              setIsModal={setIsModal}
            />
            <ButtonSecondary
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditOpportunityModal;
