import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import caseSlice from "./slices/caseSlice";
import candidateReducer from "../apis/slices/candidateSlice";
import opportunityReducer from "././slices/opportunitySlice";
import newReducer from "././slices/newSlice";
import dashboardSlice from "./slices/dashboardSlice";
const store = configureStore({
  reducer: {
    auth: authSlice,
    cases: caseSlice,
    opportunities: opportunityReducer,
    candidates: candidateReducer,
    news: newReducer,
    dashboard: dashboardSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
