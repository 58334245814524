import React from "react";
import "../../styles/text.scss";

interface MyTextSecondaryProps {
  title: string;
}

const TextSecondary = ({ title }: MyTextSecondaryProps) => {
  return <span className="cdis--text-secondary">{title}</span>;
};

export default TextSecondary;
