import React, { useState } from "react";
import { Divider, Modal } from "antd";
import ButtonOutlined from "../../../Biblio/Buttons/ButtonOutlined";
import "../../../styles/modals.scss";
import more from "../../../assets/icons/more.svg";

interface ModalProps {
  data: any;
}

const DetailsModal = ({ ...props }: ModalProps) => {
  const [isModal, setIsModal] = useState(false);

  const showModal = () => {
    setIsModal(true);
  };

  const handleCancel = () => {
    setIsModal(false);
  };
  console.log(props?.data);
  return (
    <>
      <img
        src={more}
        alt="more"
        style={{ cursor: "pointer" }}
        onClick={() => {
          showModal();
        }}
      />
      <Modal open={isModal} footer={null} onCancel={handleCancel}>
        <div className="cdis--modal-edit-opportunity-container cdis--modal-delete">
          <h1>بيانات الحالة </h1>
          <Divider className="cdis--divider" />

          <div className="cdis-details-modal">
            <h3>تفاصيل الحالة المضافة</h3>
            <div className="cdis--details-modal-content">
              <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                <h4> الاسم و اللقب : </h4>
                <h5>{props?.data?.fullNameConcerned}</h5>
              </div>
              <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                <h4>المستوى التعليمي : </h4>
                <h5>{props?.data?.levelScolaire}</h5>
              </div>
            </div>
            <div className="cdis--details-modal-content">
              <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                <h4>رقم الهاتف :</h4>
                <h5>{props?.data?.phoneConcerned} </h5>
              </div>
              <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                <h4>التغطية الإجتماعية : </h4>
                <h5>{props?.data?.socialCoverage} </h5>
              </div>
            </div>
          </div>
          {props?.data?.source && (
            <>
              <Divider className="cdis--divider" />
              <div className="cdis-details-modal">
                <h3>مصدرالحالة</h3>
                <div className="cdis--details-modal-content">
                  <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                    <h4> الاسم و اللقب : </h4>
                    <h5>{props?.data?.source?.fullNameSource}</h5>
                  </div>
                  <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                    <h4> الصفة :</h4>
                    <h5>{props?.data?.source?.status}</h5>
                  </div>
                </div>
                <div className="cdis--details-modal-content">
                  <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                    <h4>رقم الهاتف :</h4>
                    <h5>{props?.data?.source?.phoneSource} </h5>
                  </div>
                  <div className="cdis--modal-edit-opportunity-inputs-container cdis--modal-align">
                    <h4>البريد الالكتروني :</h4>
                    <h5>{props?.data?.source?.email}</h5>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="cdis--modal-buttons">
            <ButtonOutlined
              onClick={() => {
                handleCancel();
              }}
              title="إلغاء"
              
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailsModal;
